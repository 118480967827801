// import { API } from "aws-amplify";
import { createContext, useContext, useReducer } from "react";

type Message = {
  message: string;
  imageUrl?: string;
  time: string;
  user: {
    id: string;
    displayName: string;
    imageUrl: string | undefined;
  };
};

interface ChatState {
  global: { messages: Message[] };
}

interface ChatContextType {
  state: ChatState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
}

const defaultValue: ChatContextType = {
  state: { global: { messages: [] } },
  dispatch: () => null,
};

const ChatContext = createContext<ChatContextType>(defaultValue);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "initChat":
      return {
        ...state,
        global: {
          messages: [...action.payload.messages, ...state.global.messages],
        },
      };
    case "newMessage":
      return {
        ...state,
        global: {
          ...state.global,
          messages: [...state.global.messages, action.payload.newMessage],
        },
      };
    default:
      return state;
  }
};

// @ts-expect-error
export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    global: {
      messages: [],
    },
  });

  // const { setHasUnreadMessages, isChatOpen, user } = useUserStore();

  // useEffect(() => {
  //   const observableGlobal = API.graphql({
  //     query: OnSendMessageDocument,
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   }) as any;

  //   const obsGlobal = observableGlobal.subscribe({
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //     next: (data: any) => {
  //       const { value } = data;
  //       dispatch({
  //         type: "newMessage",
  //         payload: {
  //           newMessage: value.data.onSendMessage.data,
  //         },
  //       });
  //       if (!isChatOpen && value.data.onSendMessage.data.user.id !== user?.id) {
  //         setHasUnreadMessages(true);
  //       }
  //     },
  //     error: (error: unknown) => {
  //       console.error(error);
  //     },
  //   });

  //   return () => {
  //     obsGlobal.unsubscribe();
  //   };
  // }, [isChatOpen, setHasUnreadMessages]);

  return (
    <ChatContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
