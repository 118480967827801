import { CreateChargeRequest, RoundResponse } from "@ns/api";
import { Dialog } from "@ns/client-ui";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { usePaymentStore } from "../../store/payment-store.ts";
import { useAccount } from "wagmi";
import BidButton from "../../utils/rainbow/BidButton.tsx";
import SecurityIcon from "../svg-components/security-icon.tsx";
import Rainbow from "../svg-components/rainbow.tsx";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  placeBid: (data: CreateChargeRequest) => void;
}

const ChoosePaymentDialog = ({ isOpen, onClose, placeBid }: Props) => {
  const { t } = useTranslation();
  const { openConnectModal } = useConnectModal();
  const { isConnected } = useAccount();

  const { bidData } = usePaymentStore();

  const { data: round } = useQuery({
    queryKey: ["round", bidData.roundId],
    queryFn: () => api.round.getRoundById(bidData.roundId!),
    enabled: !!bidData.roundId,
  });

  const onClickConnectWallet = () => {
    openConnectModal && openConnectModal();
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div
        className={
          "text-center flex flex-col justify-center items-center text-white"
        }
      >
        <SecurityIcon />
        {isConnected ? (
          <>
            <div className={"text-[28px] font-semibold mt-8 mb-4"}>
              {t("payment.choosePayment")}
            </div>
            <div
              className={"text-sm max-w-[400px] bidCardText opacity-[0.7] mb-8"}
            >
              {t("payment.selectPreferredNetwork")}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className={"text-[28px] font-semibold mt-8 mb-4"}>
              {t("payment.connectWallet")}
            </div>
            <div
              className={"text-sm max-w-[400px] bidCardText opacity-[0.7] mb-8"}
            >
              {t("payment.selectingPayment")}
            </div>
          </>
        )}

        <div
          className={"w-full flex flex-col gap-8 justify-center items-center"}
        >
          {isConnected ? (
            <BidButton
              round={round as RoundResponse}
              placeBid={placeBid}
              onClose={onClose}
            />
          ) : (
            <button
              onClick={onClickConnectWallet}
              className="w-full max-w-xs px-6 py-3 text-white bg-[#26616C] rounded-lg border-2 border-[#26616C] hover:border-[#c59f41] hover:bg-[#001214] focus:outline-none focus:ring-4 focus:ring-[#c59f41]/50"
            >
              {t("payment.connectWallet")}
            </button>
          )}
        </div>
        <div className={"my-8 text-sm text-[#EEEEEE]"}>
          {t("payment.transactionFee")}
        </div>
        <div className="text-[#8A8A8A] text-[12px] text-center">
          {t("payment.poweredby")}
        </div>
        <Rainbow />
      </div>
    </Dialog>
  );
};

export default ChoosePaymentDialog;
