import { Card } from "@ns/client-ui";
import { FC, useState } from "react";

import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";
import CopyButton from "../svg-components/copy-button.tsx";
import SuccessButton from "../svg-components/success-button.tsx";

interface Props {
  text: string;
  currency: "BTC" | "ETH" | "SOL";
  code: string;
}

const ReferralCodeCard: FC<Props> = ({ code, currency, text }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Card
      className={
        "w-full text-white p-4 flex justify-between max-w-[350px] border-inputBorderIdle"
      }
    >
      <div className={"flex flex-col"}>
        <div className={"flex items-center gap-2 mb-4"}>
          <img src={CURRENCY_IMAGE[currency]} alt="image" />
          <div className={"text-textGray7"}>{text}</div>
        </div>
        <div className={"text-sm"}>{code}</div>
      </div>
      <div
        className={"cursor-pointer flex-1 flex justify-end items-center"}
        onClick={handleCopy}
      >
        {copied ? (
          <SuccessButton className={"w-6 h-6"} />
        ) : (
          <CopyButton className={"w-6 h-6"} />
        )}
      </div>
    </Card>
  );
};

export default ReferralCodeCard;
