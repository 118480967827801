import classNames from 'classnames';

interface ProgressBarProps {
  progress: number;
  className?: string;
}

const ProgressBar = ({ progress, className }: ProgressBarProps) => {
  const interpolateColor = (color1: string, color2: string, factor: number): string => {
    const hexToRgb = (hex: string) => {
      const bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    const rgbToHex = (rgb: number[]) => {
      return `#${rgb.map((x) => x.toString(16).padStart(2, '0')).join('')}`;
    };

    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    const resultRgb = rgb1.map((value, index) => {
      return Math.round(value + factor * (rgb2[index] - value));
    });

    return rgbToHex(resultRgb);
  };

  const handleColor = (): string => {
    const color1 = '#00FDF6';
    const color2 = '#00D409';
    const color3 = '#EAE201';
    const color4 = '#FE6D00';
    const color5 = '#DF000E';
    const prog = Number(progress);

    if (prog < 25) {
      const factor = (prog - 5) / 30;
      return interpolateColor(color1, color2, Math.max(0, Math.min(1, factor)));
    }
    if (prog < 50) {
      const factor = (prog - 25) / 30;
      return interpolateColor(color2, color3, Math.max(0, Math.min(1, factor)));
    }
    if (prog < 75) {
      const factor = (prog - 50) / 30;
      return interpolateColor(color3, color4, Math.max(0, Math.min(1, factor)));
    }
    if (prog < 100) {
      const factor = (prog - 75) / 40;
      return interpolateColor(color4, color5, Math.max(0, Math.min(1, factor)));
    }
    return color5;
  };

  const progressColor = handleColor();

  return (
    <div className={classNames('progress-bar-container', className)}>
      <div className="progress-bar-title text-[16px] text-center" style={{ color: progressColor }}>
        {progress}%
      </div>
      <div
        className="w-full rounded-full h-2 overflow-hidden"
        style={{
          backgroundColor: `${progressColor}4D`,
        }}
      >
        <div
          className="h-full rounded-full"
          style={{
            width: `${progress}%`,
            backgroundColor: progressColor,
          }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
