import { CreateChargeResponse } from "@ns/api";
import { create } from "zustand";

import { PaymentPlatformEnum } from "../enums/payment-platform.enum.ts";

type BidData = {
  bid: number;
  currency: string;
  promoCode?: string;
  referralId?: string;
  roundId: string;
};

export type Payment = {
  paymentType: PaymentPlatformEnum | null;
  setPaymentTypeHandler: (type: PaymentPlatformEnum) => void;
  isCoinPaymentOpen: boolean;
  setIsCoinPaymentOpen: (val: boolean) => void;
  coinPaymentInfo: CreateChargeResponse;
  setCoinPaymentInfo: (data: CreateChargeResponse) => void;
  bidData: BidData;
  setBidData: (data: BidData) => void;
  isPaymentProcessingOpen: boolean;
  setIsPaymentProcessingOpen: (val: boolean) => void;
  isPaymentProcessingRainbowOpen: boolean;
  setIsPaymentProcessingRainbowOpen: (val: boolean) => void;
};

export const usePaymentStore = create<Payment>((set) => ({
  paymentType: null,
  isPaymentProcessingOpen: false,
  isPaymentProcessingRainbowOpen: false,
  coinPaymentInfo: {
    checkoutId: "",
    transactionId: "",
    gift: false,
    qrCodeUrl: "",
    walletAddress: "",
    checkoutUrl: "",
    amount: "0",
    statusUrl: "",
  },
  bidData: {
    bid: 0,
    currency: "",
    promoCode: "",
    referralId: "",
    roundId: "",
  },
  isCoinPaymentOpen: false,
  setPaymentTypeHandler: (type: PaymentPlatformEnum) =>
    set(() => ({ paymentType: type })),
  setIsCoinPaymentOpen: (val: boolean) =>
    set(() => ({ isCoinPaymentOpen: val })),
  setIsPaymentProcessingOpen: (val: boolean) =>
    set(() => ({ isPaymentProcessingOpen: val })),
  setIsPaymentProcessingRainbowOpen: (val: boolean) =>
    set(() => ({ isPaymentProcessingRainbowOpen: val })),
  setCoinPaymentInfo: (data: CreateChargeResponse) =>
    set(() => ({ coinPaymentInfo: data })),
  setBidData: (data: BidData) => set(() => ({ bidData: data })),
}));
