/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrenciesResponse } from "../models/CurrenciesResponse";
import type { LanguageResponse } from "../models/LanguageResponse";
import type { PageableLanguageResponse } from "../models/PageableLanguageResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class ResourcesApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get currencies
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns CurrenciesResponse Get currencies response
   * @throws ApiError
   */
  public getCurrencies(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<CurrenciesResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/resources/currencies",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get fiat currencies
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns CurrenciesResponse Get fiat currencies response
   * @throws ApiError
   */
  public getFiatCurrencies(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<CurrenciesResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/resources/fiat-currencies",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get languages
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableLanguageResponse Get languages response
   * @throws ApiError
   */
  public getLanguages(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableLanguageResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/resources/languages?limit=20",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get language by id
   * @param id
   * @returns LanguageResponse Get language by id
   * @throws ApiError
   */
  public getLanguageById(id: string): CancelablePromise<LanguageResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/resources/languages/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
