import { Card, CardContent } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import { CURRENCY_IMAGE } from "../../constants/currency-images";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { formatDate } from "../../helpers/helpers";

export type AllRounds = {
  roundNumber: string;
  roundStatus: string;
  winningBid?: string;
  currency: string;
  date: string;
  roundClosed?: string;
  prize: number;
};

const AllRoundsCard = ({
  roundNumber,
  roundStatus,
  winningBid,
  currency,
  date,
  roundClosed,
  prize,
}: AllRounds) => {
  const { t } = useTranslation();

  return (
    <Card className="bg-transparent h-fit w-full p-[16px] h-fit border-[1px] border-solid border-tabBorderColor rounded text-bidCardText">
      <CardContent className="flex flex-col p-0">
        <div className={"hidden sm:block"}>
          <div className="flex justify-between text-[16px]">
            <span className="w-1/5 text-center">
              {`${t("bidCard.round")} ${roundNumber}`}
            </span>
            <span className="w-1/5 text-center">
              {t("myBidsCard.roundStatus")}
            </span>
            <span className="w-1/5 text-center">
              {t("myBidsCard.winningBid")}
            </span>
            <span className="w-1/5 text-center">
              {roundClosed
                ? t("myBidsCard.dateClosed")
                : t("myBidsCard.dateStarted")}
            </span>
            <span className="w-1/5 text-center">{t("myBidsCard.reward")}</span>
          </div>
          <div className="border-b border-tabBorderColor my-4"></div>
          <div className="flex justify-between text-[14px]">
            <div className="w-1/5 flex justify-center items-center">
              <img
                src={CURRENCY_IMAGE[currency as keyof typeof CURRENCY_IMAGE]}
                alt="Currency"
                className="w-[18px]"
              />
            </div>
            <span
              className={`${roundStatus === "IN_PROGRESS" ? "text-success" : "text-red-400"} w-1/5 text-center`}
            >
              {t(`common.status.${roundStatus}`)}
            </span>
            <span className="w-1/5 text-center">
              {winningBid
                ? `${winningBid} ${environmentVariables.fiatCurrency}`
                : "-"}
            </span>
            <span className="w-1/5 text-center">
              {roundClosed
                ? formatDate(new Date(roundClosed))
                : formatDate(new Date(date))}
            </span>
            <span className="w-1/5 text-center">{`${prize} ${currency}`}</span>
          </div>
        </div>

        {/*MOBILE CARD*/}
        <div className={"h-full w-full sm:hidden"}>
          <div className="flex w-full flex-col justify-between text-[16px]">
            <div className={"flex justify-between"}>
              <span>{`${t("bidCard.round")} ${roundNumber}`}</span>
              <div className="flex justify-center items-center">
                <img
                  src={CURRENCY_IMAGE[currency as keyof typeof CURRENCY_IMAGE]}
                  alt="Currency"
                  className="w-[18px]"
                />
              </div>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.roundStatus")}</span>
              <span
                className={`${roundStatus === "IN_PROGRESS" ? "text-success" : "text-red-400"}`}
              >
                {t(`common.status.${roundStatus}`)}
              </span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.winningBid")}</span>
              <span>
                {winningBid
                  ? `${winningBid} ${environmentVariables.fiatCurrency}`
                  : "-"}
              </span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>
                {roundClosed
                  ? t("myBidsCard.dateClosed")
                  : t("myBidsCard.dateStarted")}
              </span>
              <span>
                {roundClosed
                  ? formatDate(new Date(roundClosed))
                  : formatDate(new Date(date))}
              </span>
            </div>
            <div className="border-b border-tabBorderColor my-2"></div>
            <div className={"flex justify-between"}>
              <span>{t("myBidsCard.reward")}</span>
              <span>{`${prize} ${currency}`}</span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AllRoundsCard;
