import { CreateChargeRequest } from "@ns/api";
import { useToast } from "@ns/styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";

import { api } from "../api/client-api";
import { PlaceBidData } from "../schemas/form/place-bid-schema";
import { useAuthContext } from "../store/auth-store.ts";
import { useDialogContext } from "../store/dialog-store.ts";
import { usePaymentStore } from "../store/payment-store.ts";
import useUserStore from "../store/user-store.ts";

import BidDialog from "./dialogs/bid-dialog";
import ChoosePaymentDialog from "./dialogs/choose-payment-dialog.tsx";
import CoinPaymentDialog from "./dialogs/coin-payment-dialog.tsx";
import { PaymentProcessingDialog } from "./dialogs/payment-processing-dialog.tsx";
import { PopupBlockedDialog } from "./dialogs/popup-blocked-dialog.tsx";
import RoundCarousel, { CarouselData } from "./round-carousel";
import AnimatedButton from "./ui/animated-button.tsx";
import CircleData from "./ui/circle-data";

import bellSvg from "/svg/bell.svg";
import btcSvg from "/svg/btc-svg.svg";
import ethSvg from "/svg/eth-svg.svg";
import giftSvg from "/svg/gift.svg";
import solSvg from "/svg/sol-svg.svg";

import { PaymentProcessingDialogRainbow } from "./dialogs/payment-processing-dialog-rainbow.tsx";
import CircleEmpty from "./ui/circle-empty";
import RewardCard from "./ui/reward-card";
import CryptoDropdown from "./form/controlled-cryptodropdown.tsx";

export type Currency = {
  code: "BTC" | "ETH" | "SOL";
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
};

export type FiatCurrency = {
  code: string;
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
};

export type RoundData = {
  number: string;
  progress: string;
  currency: Currency;
  id: string;
  status: "IN_PROGRESS" | "COMPLETED" | "DEFAULT";
  entryFee: string;
  createdAt: string;
  updatedAt: string;
  type: "STANDARD" | "PROMO";
  fiatCurrency: FiatCurrency;
};

const RoundControl = () => {
  const tabsData = [
    { label: "BTC", value: "BTC", icon: btcSvg },
    { label: "ETH", value: "ETH", icon: ethSvg },
    { label: "SOL", value: "SOL", icon: solSvg },
  ];

  const {
    setIsGiftDialogOpen,
    toggleNotificationOpen,
    setSelectedTab,
    selectedTab,
  } = useDialogContext();
  const { setIsLoginModalOpen } = useAuthContext();

  // const [checkoutId, setCheckoutId] = useState<string | null>(null);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const { user, setUser } = useUserStore();

  const { data: roundsData } = useQuery({
    queryFn: async () => api.round.getRounds("LATEST"),
    queryKey: ["rounds"],
    retry: false,
    refetchOnWindowFocus: false,
  });
  useQuery({
    queryKey: ["auth"],
    queryFn: () => api.auth.getAuthWhoAmI(),
    onSuccess: (data) => {
      setUser(data);
    },
    enabled: !user?.id,
  });
  const { setBidData, setCoinPaymentInfo, setIsCoinPaymentOpen } =
    usePaymentStore();
  const { toast } = useToast();
  const circleRef = useRef<Carousel>(null);

  const { mutate: placeBid } = useMutation({
    mutationFn: (data: CreateChargeRequest) => api.payment.charge(data),
    onSuccess: (response) => {
      // if (!response.gift) {
      //   setCheckoutId(response.checkoutId);

      //   const url = `https://commerce.coinbase.com/checkout/${response.checkoutId}`;
      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.target = "_blank";
      //   a.rel = "noopener noreferrer";
      //   document.body.appendChild(a);
      //   a.click();
      //   document.body.removeChild(a);
      // }
      setCoinPaymentInfo(response);
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: t("common.errors.somethingWentWrong"),
      });
      setIsCoinPaymentOpen(false);
    },
  });

  const { t } = useTranslation();
  const { handleSubmit, setValue, getValues } = useFormContext<PlaceBidData>();
  const [index, setIndex] = useState<number>(0);
  const [bidDialog, setBidDialog] = useState<boolean>(false);
  const [isChoosePaymentDialogOpen, setIsChoosePaymentDialogOpen] =
    useState<boolean>(false);

  const formatedData = useMemo(() => {
    const defaultRoundBtc: RoundData = {
      number: "0",
      progress: "",
      currency: { code: "BTC" } as Currency,
      id: "",
      status: "DEFAULT",
      type: "STANDARD",
      fiatCurrency: {} as FiatCurrency,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      entryFee: "0",
    };

    const defaultRoundEth: RoundData = {
      number: "0",
      progress: "",
      currency: { code: "ETH" } as Currency,
      id: "",
      status: "DEFAULT",
      type: "STANDARD",
      fiatCurrency: {} as FiatCurrency,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      entryFee: "0",
    };

    const defaultRoundSol: RoundData = {
      number: "0",
      progress: "",
      currency: { code: "SOL" } as Currency,
      id: "",
      status: "DEFAULT",
      type: "STANDARD",
      fiatCurrency: {} as FiatCurrency,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      entryFee: "0",
    };

    const apiData = roundsData?.items || [];

    if (!apiData.length) {
      apiData.push(defaultRoundBtc, defaultRoundEth, defaultRoundSol);
    } else if (apiData.length < 2) {
      const currencyCode = apiData[0].currency.code === "ETH" ? "BTC" : "ETH";
      apiData.push({
        ...defaultRoundBtc,
        currency: { code: currencyCode } as Currency,
      });
    }
    const renderData = [...apiData, ...apiData];

    return renderData.map((element, index) => ({
      ...element,
      id: `${element.id}`,
      key: `${element.id}-${index}`,
    }));
  }, [roundsData]);

  useEffect(() => {
    if (selectedTab && roundsData?.items) {
      const foundIndex = roundsData.items.findIndex(
        (element) => element.currency.code === selectedTab
      );

      if (foundIndex !== index) {
        setIndex(foundIndex);
        setValue("currency", formatedData[foundIndex]?.currency?.code);
        setValue("roundId", formatedData[foundIndex]?.id);
        circleRef?.current?.setState({
          goToSlide: foundIndex,
        });
      }

      setSelectedTab(undefined);
    }
  }, [
    selectedTab,
    roundsData?.items,
    setSelectedTab,
    setIndex,
    index,
    setValue,
    formatedData,
  ]);

  const { data: checkNotifyMe, refetch } = useQuery({
    queryKey: ["checkNotifyMe", formatedData[index]?.id],
    queryFn: () =>
      api.notifyMe.checkIfUserIsSubscribedToNotifyMe(formatedData[index]!.id),
    enabled: !!formatedData[index]?.id && !!user?.id,
  });

  const { mutate: notifyMe } = useMutation({
    mutationFn: () => api.notifyMe.subscribeToNotifyMe(formatedData[index]!.id),
    onSuccess: async () => {
      await refetch();
    },
    onError: () => {},
  });

  const slideData = useMemo((): CarouselData[] => {
    return formatedData.map((data, i) => {
      const randomId = uuidv4();
      return {
        key: randomId,
        content:
          data.status === "IN_PROGRESS" || data.status === "COMPLETED" ? (
            <CircleData
              id={randomId}
              round={Number(data.number)}
              progress={data.progress}
              // @ts-expect-error - currency is not undefined
              currency={data.currency.code}
              hasBlur={index !== i}
            />
          ) : (
            <CircleEmpty />
          ),
      };
    });
  }, [formatedData, index]);

  useEffect(() => {
    const id = formatedData[index].id;
    const svgElement = document.getElementById(`paint0_linear_${id}-${index}`);
    if (svgElement) {
      svgElement.setAttribute("dummy-attr", "true");
      setTimeout(() => {
        svgElement.removeAttribute("dummy-attr");
      }, 0);
    }
  }, [index]);

  const changeSlide = (direction: number) => {
    setIndex(
      (prevIndex) =>
        (prevIndex + direction + slideData.length) % slideData.length
    );
  };

  const onTabChange = useCallback(
    (type: string): void => {
      if (getValues("currency") === type) return;
      const apiData = roundsData?.items || [];
      const index = apiData.findIndex(
        (element) => element.currency.code === type
      );
      setIndex(index);
    },
    [roundsData, setIndex, getValues]
  );

  const toggleBidDialog = () => {
    setBidDialog((bidDialog) => !bidDialog);
  };

  const toggleDialog = useCallback(() => {
    !user?.id ? setIsLoginModalOpen(true) : setBidDialog(true);
  }, [user, setIsLoginModalOpen, setBidDialog]);

  const renderActionBtn = useMemo(() => {
    if (formatedData[index]?.status === "IN_PROGRESS") {
      return (
        <AnimatedButton
          onClick={toggleDialog}
          className={"rounded-[100px] max-w-[220px]"}
          text={t("common.placeBid")}
          animatedButtonClass={
            formatedData[index].currency.code === "BTC"
              ? "animated-button-btc"
              : "animated-button-eth"
          }
        />
      );
    } else {
      if (checkNotifyMe?.isSubscribed) {
        return (
          <span
            className={
              "w-[343px] text-textGray avenir-semibold text-center text-[16px]"
            }
          >
            {t("common.willReceive")}
          </span>
        );
      }
      return (
        <AnimatedButton
          onClick={() => {
            user ? notifyMe() : setIsLoginModalOpen(true);
          }}
          className={"rounded-[100px] max-w-[220px]"}
          text={t("common.notifyMe")}
        />
      );
    }
  }, [
    setIsLoginModalOpen,
    user,
    toggleDialog,
    t,
    notifyMe,
    formatedData,
    index,
    checkNotifyMe,
  ]);

  const onSubmit = handleSubmit((data) => {
    const formated = { ...data, bid: Number(data.bid) };
    setBidData(formated);
    setBidDialog(false);
    setIsChoosePaymentDialogOpen(true);
  });

  useEffect(() => {
    setValue("currency", formatedData[index]?.currency?.code);
    setValue("roundId", formatedData[index]?.id);
  }, [setValue, formatedData, index]);
  console.log("index");

  // useEffect(() => {
  //   if (checkoutId) {
  //     setBidDialog(false);
  //     if (paymentType === PaymentPlatformEnum.COIN_BASE) {
  //       const btn = document.getElementById("coinbaseModal");
  //       btn?.click();
  //     }
  //   }
  // }, [checkoutId, setBidDialog, paymentType]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCarousel(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="flex w-full pl-8 pr-8 lg:pl-0 lg:pr-0 gap-[32px] items-center justify-around">
        {user && (
          <img
            onClick={() => setIsGiftDialogOpen(true)}
            src={giftSvg}
            alt="gift"
            className={"relative z-[1] sm:hidden"}
          />
        )}
        <CryptoDropdown
          name="currency"
          tabsData={tabsData}
          onTabChange={onTabChange}
        />
        {user && (
          <img
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleNotificationOpen();
            }}
            src={bellSvg}
            alt="bell"
            className={"relative z-[1] sm:hidden"}
          />
        )}
      </div>
      {showCarousel && (
        <RoundCarousel
          slideData={slideData}
          index={index}
          onClick={changeSlide}
          ref={circleRef}
        />
      )}
      <div className="hidden md:block absolute right-[10px] lg:right-[20px] xl:right-[100px] z-40 top-1/2 transform -translate-y-1/2">
        <RewardCard data={roundsData?.items && roundsData.items} />
      </div>
      <div
        className={`w-full max-w-[200px] md:max-w-[340px] flex items-center justify-center z-10 mb-4`}
      >
        {renderActionBtn}
      </div>
      <div className="w-full max-h-[90px] h-full md:hidden relative max-w-[225px] w-[75%]">
        <div className="h-full min-w-[220px] sm:min-w-[225px]">
          <RewardCard
            data={roundsData?.items && roundsData.items}
            className={"h-full"}
          />
        </div>
      </div>
      {bidDialog && (
        <BidDialog
          isOpen={bidDialog}
          onClose={toggleBidDialog}
          onSuccess={onSubmit}
          roundId={bidDialog ? formatedData[index]?.id : undefined}
          entryFee={Number(formatedData[index]?.entryFee)}
        />
      )}
      <ChoosePaymentDialog
        isOpen={isChoosePaymentDialogOpen}
        onClose={() => setIsChoosePaymentDialogOpen(false)}
        placeBid={placeBid}
      />
      <CoinPaymentDialog />
      <PaymentProcessingDialog />
      <PaymentProcessingDialogRainbow />
      <PopupBlockedDialog placeBid={placeBid} />
    </>
  );
};

export default RoundControl;
