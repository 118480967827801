//TODO: ATTILA - Add translations for all the notifications PRIZE
export const translations = (roundNumber?: number) => {
  return {
    en: {
      ROUND_START_BTC: {
        title: `BTC Round #${roundNumber} Has Started!`,
        text: `Bid for Bitcoin! Place your lowest unique bid in our Bitcoin auction and buy 1 BTC for price you bid!`,
      },
      ROUND_START_ETH: {
        title: `ETH Round #${roundNumber} Has Started!`,
        text: `Bid for Ethereum! Place your lowest unique bid in our Ethereum auction and buy 1 ETH for price you bid!`,
      },
      ROUND_START_SOL: {
        title: `SOL Round #${roundNumber} Has Started!`,
        text: `Bid for Solana! Place your lowest unique bid in our Solana auction and buy 1 SOL for price you bid!`,
      },
      ROUND_FINISH_BTC: {
        title: `BTC Round #${roundNumber} is Finished!`,
        text: `Check the winner list to see who won and got 1 BTC.`,
      },
      ROUND_FINISH_ETH: {
        title: `ETH Round #${roundNumber} is Finished!`,
        text: `Check the winner list to see who won and got 1 ETH.`,
      },
      ROUND_FINISH_SOL: {
        title: `SOL Round #${roundNumber} is Finished!`,
        text: `Check the winner list to see who won and got 1 SOL.`,
      },
      ROUND_WINNER_BTC: {
        title: `Congratulations! You've won BTC Round #${roundNumber}!`,
        text: `Your bid is the lowest unique bid in BTC auction. You've won the round - check your rewards now!`,
      },
      ROUND_WINNER_ETH: {
        title: `Congratulations! You've won ETH Round #${roundNumber}!`,
        text: `Your bid is the lowest unique bid in ETH auction. You've won the round - check your rewards now!`,
      },
      ROUND_WINNER_SOL: {
        title: `Congratulations! You've won SOL Round #${roundNumber}!`,
        text: `Your bid is the lowest unique bid in SOL auction. You've won the round - check your rewards now!`,
      },
      ROUND_WINNER_REFERRAL_BTC: {
        title: `Congratulations for Referral Reward Winner in BTC Round #${roundNumber}!`,
        text: `You have won 5% of the market value of 1 BTC.`,
      },
      ROUND_WINNER_REFERRAL_ETH: {
        title: `Congratulations for Referral Reward Winner in ETH Round #${roundNumber}!`,
        text: `You have won 5% of the market value of 1 ETH.`,
      },
      ROUND_WINNER_REFERRAL_SOL: {
        title: `Congratulations for Referral Reward Winner in SOL Round #${roundNumber}!`,
        text: `You have won 5% of the market value of 1 SOL.`,
      },
      PAYMENT_SUCCESSFUL: {
        title: `Payment successful`,
        text: `Your payment has been processed successfully.`,
      },
    },
    de: {
      ROUND_START_BTC: {
        title: `BTC-Runde #${roundNumber} hat begonnen!`,
        text: `Bieten Sie auf Bitcoin! Geben Sie Ihr niedrigstes einzigartiges Gebot in unserer Bitcoin-Auktion ab und kaufen Sie 1 BTC zum gebotenen Preis!`,
      },
      ROUND_START_ETH: {
        title: `ETH-Runde #${roundNumber} hat begonnen!`,
        text: `Bieten Sie auf Ethereum! Geben Sie Ihr niedrigstes einzigartiges Gebot in unserer Ethereum-Auktion ab und kaufen Sie 1 ETH zum gebotenen Preis!`,
      },
      ROUND_START_SOL: {
        title: `SOL-Runde #${roundNumber} hat begonnen!`,
        text: `Bieten Sie auf Solana! Geben Sie Ihr niedrigstes einzigartiges Gebot in unserer Ethereum-Auktion ab und kaufen Sie 1 SOL zum gebotenen Preis!`,
      },
      ROUND_FINISH_BTC: {
        title: `BTC-Runde #${roundNumber} ist beendet!`,
        text: `Überprüfen Sie die Gewinnerliste, um zu sehen, wer gewonnen hat und 1 BTC erhalten hat.`,
      },
      ROUND_FINISH_ETH: {
        title: `ETH-Runde #${roundNumber} ist beendet!`,
        text: `Überprüfen Sie die Gewinnerliste, um zu sehen, wer gewonnen hat und 1 ETH erhalten hat.`,
      },
      ROUND_FINISH_SOL: {
        title: `SOL-Runde #${roundNumber} ist beendet!`,
        text: `Überprüfen Sie die Gewinnerliste, um zu sehen, wer gewonnen hat und 1 SOL erhalten hat.`,
      },
      ROUND_WINNER_BTC: {
        title: `Herzlichen Glückwunsch! Sie haben die BTC-Runde #${roundNumber} gewonnen!`,
        text: `Ihr Gebot ist das niedrigste einzigartige Gebot in der Bitcoin-Auktion. Sie haben die Runde gewonnen - überprüfen Sie jetzt Ihre Belohnungen!`,
      },
      ROUND_WINNER_ETH: {
        title: `Herzlichen Glückwunsch! Sie haben die ETH-Runde #${roundNumber} gewonnen!`,
        text: `Ihr Gebot ist das niedrigste einzigartige Gebot in der Ethereum-Auktion. Sie haben die Runde gewonnen - überprüfen Sie jetzt Ihre Belohnungen!`,
      },
      ROUND_WINNER_SOL: {
        title: `Herzlichen Glückwunsch! Sie haben die SOL-Runde #${roundNumber} gewonnen!`,
        text: `Ihr Gebot ist das niedrigste einzigartige Gebot in der Solana-Auktion. Sie haben die Runde gewonnen - überprüfen Sie jetzt Ihre Belohnungen!`,
      },
      ROUND_WINNER_REFERRAL_BTC: {
        title: `Herzlichen Glückwunsch zum Empfehlungsgewinn in der BTC-Runde #${roundNumber}!`,
        text: `Sie haben 5% des Marktwerts von 1 BTC gewonnen.`,
      },
      ROUND_WINNER_REFERRAL_ETH: {
        title: `Herzlichen Glückwunsch zum Empfehlungsgewinn in der ETH-Runde #${roundNumber}!`,
        text: `Sie haben 5% des Marktwerts von 1 ETH gewonnen.`,
      },
      ROUND_WINNER_REFERRAL_SOL: {
        title: `Herzlichen Glückwunsch zum Empfehlungsgewinn in der SOL-Runde #${roundNumber}!`,
        text: `Sie haben 5% des Marktwerts von 1 SOL gewonnen.`,
      },
      PAYMENT_SUCCESSFUL: {
        title: `Zahlung erfolgreich`,
        text: `Ihre Zahlung wurde erfolgreich bearbeitet.`,
      },
    },
    cn: {
      ROUND_START_BTC: {
        title: `BTC 第${roundNumber}轮已开始！`,
        text: `竞标比特币！在我们的比特币拍卖中出价最低的独特竞标，并以您出价的价格购买 1 BTC！`,
      },
      ROUND_START_ETH: {
        title: `ETH 第${roundNumber}轮已开始！`,
        text: `竞标以太坊！在我们的以太坊拍卖中出价最低的独特竞标，并以您出价的价格购买 1 ETH！`,
      },
      ROUND_START_SOL: {
        title: `SOL 第 #${roundNumber} 轮已开始！`,
        text: `竞标 Solana！在我们的 Solana 拍卖中出价最低的唯一竞标者将以你出价的价格购买 1 SOL！`,
      },
      ROUND_FINISH_BTC: {
        title: `BTC 第${roundNumber}轮已结束！`,
        text: `查看获胜者名单，看看谁赢得了 1 BTC.`,
      },
      ROUND_FINISH_ETH: {
        title: `ETH 第${roundNumber}轮已结束！`,
        text: `查看获胜者名单，看看谁赢得了 1 ETH.`,
      },
      ROUND_FINISH_SOL: {
        title: `SOL 第 #${roundNumber} 轮已结束！`,
        text: `查看获胜者名单，看看谁赢得了并获得了 1 SOL。`,
      },
      ROUND_WINNER_BTC: {
        title: `恭喜您！您赢得了BTC第${roundNumber}轮！`,
        text: `您的出价是比特币拍卖中最低的独特出价。您赢得了这一轮-现在检查您的奖励！`,
      },
      ROUND_WINNER_ETH: {
        title: `恭喜您！您赢得了ETH第${roundNumber}轮！`,
        text: `您的出价是以太坊拍卖中最低的独特出价。您赢得了这一轮-现在检查您的奖励！`,
      },
      ROUND_WINNER_SOL: {
        title: `恭喜！你赢得了 SOL 第 #${roundNumber} 轮！`,
        text: `你的出价是 Solana 拍卖中最低的唯一有效出价。你赢得了这一轮 - 现在查看你的奖励！`,
      },
      ROUND_WINNER_REFERRAL_BTC: {
        title: `恭喜您成为BTC第${roundNumber}轮推荐奖励的获奖者！`,
        text: `您赢得了 1 BTC 市值的 5%.`,
      },
      ROUND_WINNER_REFERRAL_ETH: {
        title: `恭喜您成为ETH第${roundNumber}轮推荐奖励的获奖者！`,
        text: `您赢得了 1 ETH 市值的 5%.`,
      },
      ROUND_WINNER_REFERRAL_SOL: {
        title: `恭喜你成为 SOL 第 #${roundNumber} 轮的推荐奖励获胜者！`,
        text: `你赢得了 1 SOL 市场价值的 5%。`,
      },
      PAYMENT_SUCCESSFUL: {
        title: `支付成功`,
        text: `您的支付已成功处理.`,
      },
    },
    tr: {
      ROUND_START_BTC: {
        title: `BTC #${roundNumber} Turu Başladı!`,
        text: `Bitcoin için teklif verin! Bitcoin açık artırmamızda en düşük benzersiz teklifi verin ve teklif ettiğiniz fiyata 1 BTC satın alın!`,
      },
      ROUND_START_ETH: {
        title: `ETH #${roundNumber} Turu Başladı!`,
        text: `Ethereum için teklif verin! Ethereum açık artırmamızda en düşük benzersiz teklifi verin ve teklif ettiğiniz fiyata 1 ETH satın alın!`,
      },
      ROUND_START_SOL: {
        title: `SOL Turu #${roundNumber} Başladı!`,
        text: `Solana için teklif verin! En düşük benzersiz teklifinizi Solana açık artırmamıza koyun ve teklifinizle 1 SOL satın alın!`,
      },
      ROUND_FINISH_BTC: {
        title: `BTC #${roundNumber} Turu Sona Erdi!`,
        text: `Kim kazandı ve 1 BTC aldı görmek için kazananlar listesini kontrol edin.`,
      },
      ROUND_FINISH_ETH: {
        title: `ETH #${roundNumber} Turu Sona Erdi!`,
        text: `Kim kazandı ve 1 ETH aldı görmek için kazananlar listesini kontrol edin.`,
      },
      ROUND_FINISH_SOL: {
        title: `SOL #${roundNumber} Turu Sona Erdi!`,
        text: `Kim kazandı ve 1 SOL aldı görmek için kazananlar listesini kontrol edin.`,
      },
      ROUND_WINNER_BTC: {
        title: `Tebrikler! BTC #${roundNumber} Turunu Kazandınız!`,
        text: `Teklifiniz, Bitcoin açık artırmasındaki en düşük benzersiz teklif. Turu kazandınız - şimdi ödüllerinizi kontrol edin!`,
      },
      ROUND_WINNER_ETH: {
        title: `Tebrikler! ETH #${roundNumber} Turunu Kazandınız!`,
        text: `Teklifiniz, Ethereum açık artırmasındaki en düşük benzersiz teklif. Turu kazandınız - şimdi ödüllerinizi kontrol edin!`,
      },
      ROUND_WINNER_SOL: {
        title: `Tebrikler! SOL Turu #${roundNumber}'u Kazandınız!`,
        text: `Teklifiniz, Solana açık artırmasındaki en düşük benzersiz tekliftir. Bu turu kazandınız - şimdi ödüllerinizi kontrol edin!`,
      },
      ROUND_WINNER_REFERRAL_BTC: {
        title: `BTC #${roundNumber} Turunda Referans Ödülü Kazandığınız İçin Tebrikler!`,
        text: `1 BTC'nin piyasa değerinin %5'ini kazandınız.`,
      },
      ROUND_WINNER_REFERRAL_ETH: {
        title: `ETH #${roundNumber} Turunda Referans Ödülü Kazandığınız İçin Tebrikler!`,
        text: `1 ETH'nin piyasa değerinin %5'ini kazandınız.`,
      },
      ROUND_WINNER_REFERRAL_SOL: {
        title: `Tebrikler! SOL Turu #${roundNumber}'da Yönlendirme Ödülü Kazananı Oldunuz!`,
        text: `1 SOL'ün piyasa değerinin %5'ini kazandınız.`,
      },
      PAYMENT_SUCCESSFUL: {
        title: `Ödeme Başarılı`,
        text: `Ödemeniz başarıyla işlendi.`,
      },
    },
    pt: {
      ROUND_START_BTC: {
        title: `A Rodada BTC #${roundNumber} Começou!`,
        text: `Dê seu lance em Bitcoin! Coloque seu lance único mais baixo em nosso leilão de Bitcoin e compre 1 BTC pelo preço que você ofertou!`,
      },
      ROUND_START_ETH: {
        title: `A Rodada ETH #${roundNumber} Começou!`,
        text: `Dê seu lance em Ethereum! Coloque seu lance único mais baixo em nosso leilão de Ethereum e compre 1 ETH pelo preço que você ofertou!`,
      },
      ROUND_START_SOL: {
        title: `A Rodada #${roundNumber} do SOL Começou!`,
        text: `Dê seu lance pelo Solana! Faça seu lance mais baixo e único no nosso leilão de Solana e compre 1 SOL pelo preço que você oferecer!`,
      },
      ROUND_FINISH_BTC: {
        title: `A Rodada BTC #${roundNumber} Terminou!`,
        text: `Verifique a lista de vencedores para ver quem ganhou e levou 1 BTC.`,
      },
      ROUND_FINISH_ETH: {
        title: `A Rodada ETH #${roundNumber} Terminou!`,
        text: `Verifique a lista de vencedores para ver quem ganhou e levou 1 ETH.`,
      },
      ROUND_FINISH_SOL: {
        title: `A rodada #${roundNumber} do SOL terminou!`,
        text: `Confira a lista de vencedores para ver quem ganhou e recebeu 1 SOL.`,
      },
      ROUND_WINNER_BTC: {
        title: `Parabéns! Você ganhou a Rodada BTC #${roundNumber}!`,
        text: `Seu lance é o lance único mais baixo no leilão de BTC. Você ganhou a rodada - verifique suas recompensas agora!`,
      },
      ROUND_WINNER_ETH: {
        title: `Parabéns! Você ganhou a Rodada ETH #${roundNumber}!`,
        text: `Seu lance é o lance único mais baixo no leilão de ETH. Você ganhou a rodada - verifique suas recompensas agora!`,
      },
      ROUND_WINNER_SOL: {
        title: `Parabéns! Você ganhou a rodada SOL #${roundNumber}!`,
        text: `Seu lance foi o menor lance único no leilão de Solana. Você ganhou a rodada - confira suas recompensas agora!`,
      },
      ROUND_WINNER_REFERRAL_BTC: {
        title: `Parabéns pelo Prêmio de Indicação na Rodada BTC #${roundNumber}!`,
        text: `Você ganhou 5% do valor de mercado de 1 BTC.`,
      },
      ROUND_WINNER_REFERRAL_ETH: {
        title: `Parabéns pelo Prêmio de Indicação na Rodada ETH #${roundNumber}!`,
        text: `Você ganhou 5% do valor de mercado de 1 ETH.`,
      },
      ROUND_WINNER_REFERRAL_SOL: {
        title: `Parabéns por Ser o Vencedor do Recompensa de Indicação na Rodada SOL #${roundNumber}!`,
        text: `Você ganhou 5% do valor de mercado de 1 SOL.`,
      },
      PAYMENT_SUCCESSFUL: {
        title: `Pagamento bem-sucedido`,
        text: `Seu pagamento foi processado com sucesso.`,
      },
    },
    fr: {
      ROUND_START_BTC: {
        title: `La Ronde BTC #${roundNumber} a Commencé !`,
        text: `Enchérissez pour Bitcoin ! Placez votre enchère unique la plus basse dans notre enchère de Bitcoin et achetez 1 BTC au prix que vous avez proposé !`,
      },
      ROUND_START_ETH: {
        title: `La Ronde ETH #${roundNumber} a Commencé !`,
        text: `Enchérissez pour Ethereum ! Placez votre enchère unique la plus basse dans notre enchère d'Ethereum et achetez 1 ETH au prix que vous avez proposé !`,
      },
      ROUND_START_SOL: {
        title: `Le Ronde #${roundNumber} de SOL a commencé !`,
        text: `Enchérissez pour Solana ! Placez votre enchère la plus basse et unique dans notre enchère Solana et achetez 1 SOL au prix que vous avez proposé !`,
      },
      ROUND_FINISH_BTC: {
        title: `La Ronde BTC #${roundNumber} est Terminée !`,
        text: `Consultez la liste des gagnants pour voir qui a remporté 1 BTC.`,
      },
      ROUND_FINISH_ETH: {
        title: `La Ronde ETH #${roundNumber} est Terminée !`,
        text: `Consultez la liste des gagnants pour voir qui a remporté 1 ETH.`,
      },
      ROUND_FINISH_SOL: {
        title: `Le tour #${roundNumber} de SOL est terminé !`,
        text: `Consultez la liste des gagnants pour voir qui a gagné et a obtenu 1 SOL.`,
      },
      ROUND_WINNER_BTC: {
        title: `Félicitations ! Vous avez gagné la Ronde BTC #${roundNumber} !`,
        text: `Votre enchère est l'enchère unique la plus basse dans la vente aux enchères de BTC. Vous avez remporté la ronde - vérifiez vos récompenses maintenant !`,
      },
      ROUND_WINNER_ETH: {
        title: `Félicitations ! Vous avez gagné la Ronde ETH #${roundNumber} !`,
        text: `Votre enchère est l'enchère unique la plus basse dans la vente aux enchères d'ETH. Vous avez remporté la ronde - vérifiez vos récompenses maintenant !`,
      },
      ROUND_WINNER_SOL: {
        title: `Félicitations ! Vous avez gagné le tour SOL #${roundNumber} !`,
        text: `Votre enchère est l'enchère unique la plus basse dans l'enchère Solana. Vous avez gagné le tour - consultez vos récompenses maintenant !`,
      },
      ROUND_WINNER_REFERRAL_BTC: {
        title: `Félicitations pour la Récompense de Parrainage dans la Ronde BTC #${roundNumber} !`,
        text: `Vous avez gagné 5% de la valeur marchande de 1 BTC.`,
      },
      ROUND_WINNER_REFERRAL_ETH: {
        title: `Félicitations pour la Récompense de Parrainage dans la Ronde ETH #${roundNumber} !`,
        text: `Vous avez gagné 5% de la valeur marchande de 1 ETH.`,
      },
      ROUND_WINNER_REFERRAL_SOL: {
        title: `Félicitations pour avoir gagné la récompense de parrainage dans le tour SOL #${roundNumber} !`,
        text: `Vous avez gagné 5% de la valeur marchande de 1 SOL.`,
      },
      PAYMENT_SUCCESSFUL: {
        title: `Paiement réussi`,
        text: `Votre paiement a été traité avec succès.`,
      },
    },
  };
};
