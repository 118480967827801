import { useRef, useState, useEffect } from "react";
import { useOutsideClick } from "../../../hooks/use-outside-click";
import chevronSvg from "/svg/chevron.svg";
import { useFormContext, useWatch, Controller } from "react-hook-form";

type TabData = {
  value: string;
  label: string;
  icon: string;
};

interface CryptoDropdownProps {
  name: string;
  tabsData: TabData[];
  onTabChange: (tab: string) => void;
}

const CryptoDropdown = ({
  name,
  tabsData,
  onTabChange,
}: CryptoDropdownProps) => {
  const { control } = useFormContext();
  const value = useWatch({ name: name });

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabsData[0]);
  useOutsideClick([dropdownRef], () => setIsOpen(false));

  useEffect(() => {
    const newSelectedTab = tabsData.find((tab) => tab.value === value);
    if (newSelectedTab) {
      setSelectedTab(newSelectedTab);
    }
  }, [value, tabsData]);

  const handleTabChange = (tab: TabData) => {
    setSelectedTab(tab);
    onTabChange(tab.value);
    setIsOpen(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="z-30 w-full flex items-center justify-center">
          <div
            className="relative w-[160px] h-[37px] md:w-[350px]"
            ref={dropdownRef}
          >
            <div
              className="flex items-center justify-between w-full h-full bg-backgroundDeepTeal text-white px-3 cursor-pointer rounded-md border border-[#9197a466]"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="flex items-center">
                <img
                  src={selectedTab?.icon}
                  alt={`${selectedTab.label} Logo`}
                  className="h-6 w-6 mr-2"
                />
                <span>{selectedTab.label}</span>
              </div>
              <div className="flex items-center space-x-1">
                {tabsData
                  .filter((tab) => tab.value !== selectedTab.value)
                  .map((tab) => (
                    <img
                      key={tab.value}
                      src={tab?.icon}
                      alt={`${tab.label} Logo`}
                      className="mr-[-10px] h-4 w-4"
                    />
                  ))}
                <img
                  src={chevronSvg}
                  alt="Chevron"
                  className={`transition-transform ${isOpen ? "rotate-180" : ""}`}
                />
              </div>
            </div>

            {isOpen && (
              <div className="absolute left-0 top-full bg-backgroundDeepTeal w-full rounded-md shadow-lg border border-[#9197a466]">
                {tabsData.map((tab) => (
                  <div
                    onClick={() => {
                      handleTabChange(tab);
                      field.onChange(tab.value);
                    }}
                    key={tab.value}
                    className="flex items-center mt-1 px-3 py-2 cursor-pointer rounded-md hover:bg-gray-700 text-white"
                  >
                    <img
                      src={tab?.icon}
                      alt={`${tab.label} Logo`}
                      className="h-6 w-6 mr-2"
                    />
                    <span>{tab.label}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default CryptoDropdown;
