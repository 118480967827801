import { RoundWinnerResponse } from "@ns/api";
import { forwardRef, useEffect, useState } from "react";
import Carousel from "react-spring-3d-carousel";

import chevrons from "/svg/chevrons.svg";

import useScreenSize from "../../hooks/use-screen-size.ts";

export type CarouselData = {
  key: number | string;
  content: JSX.Element;
};

interface RoundCarouselProps {
  slideData: CarouselData[] | RoundWinnerResponse[];
  index: number;
  onClick: (index: number) => void;
  className?: string;
}

const RoundCarousel = forwardRef<Carousel, RoundCarouselProps>(
  ({ slideData, index, onClick, className }, ref) => {
    const [xDown, setXDown] = useState<number | null>(null);
    const [yDown, setYDown] = useState<number | null>(null);

    const offsetFn = (offsetFromCenter: number): { left: string } => {
      return {
        left: `${50 + offsetFromCenter * -14}%`,
      };
    };

    const { height, width } = useScreenSize();

    useEffect(() => {
      // @ts-expect-error - TS doesn't know that ref is a Carousel
      ref?.current?.setState({
        goToSlide: index,
      });
    }, [index]);

    const handleTouchStart = (evt: React.TouchEvent) => {
      const firstTouch = evt.touches[0];
      setXDown(firstTouch.clientX);
      setYDown(firstTouch.clientY);
    };

    const handleTouchMove = (evt: React.TouchEvent) => {
      if (!xDown || !yDown) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          // left swipe
          onClick(1);
        } else {
          // right swipe
          onClick(-1);
        }
      }

      setXDown(null);
      setYDown(null);
    };

    return (
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        style={{
          height: `calc(${height}px - ${width < 640 ? "440px" : "420px"})`,
        }}
        className="h-fit carousel-wrapper max-h-[500px] flex items-center w-full max-w-[30rem] lg:max-w-[45rem] relative"
      >
        <img
          src={chevrons}
          onClick={() => onClick(1)}
          className="absolute left-4 lg:left-16 xl:left-0 rotate-[90deg] cursor-pointer z-10"
          alt="toggle-left"
        />
        <div
          className={`max-h-[250px] xl:max-h-[300px] xl:max-h-[400px] 2xl:max-h-[500px] h-full w-full carousel-wrapper ${className}`}
        >
          <Carousel
            ref={ref}
            slides={slideData}
            goToSlide={index}
            offsetRadius={1}
            showNavigation={false}
            offsetFn={offsetFn}
          />
        </div>
        <img
          src={chevrons}
          onClick={() => onClick(-1)}
          className="absolute right-4 lg:right-16 xl:right-0 rotate-[-90deg] cursor-pointer z-10"
          alt="toggle-right"
        />
      </div>
    );
  }
);

export default RoundCarousel;
