/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateChargeRequest } from "../models/CreateChargeRequest";
import type { CreateChargeResponse } from "../models/CreateChargeResponse";
import type { UseCouponRequest } from "../models/UseCouponRequest";
import type { UseCouponResponse } from "../models/UseCouponResponse";
import type { VerifyBidRequest } from "../models/VerifyBidRequest";
import type { VerifyBidResponse } from "../models/VerifyBidResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class PaymentApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create payment charge
   * @param requestBody Payment charge
   * @returns CreateChargeResponse Successfully created payment charge
   * @throws ApiError
   */
  public charge(
    requestBody?: CreateChargeRequest
  ): CancelablePromise<CreateChargeResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/payment/charge",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  public getMerchantAddress(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: "/merchant-address",
      errors: {
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Use coupon for payment
   * @param requestBody Use coupon for payment
   * @returns UseCouponResponse Price preview
   * @throws ApiError
   */
  public useCoupon(
    requestBody?: UseCouponRequest
  ): CancelablePromise<UseCouponResponse> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/payment/coupon",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Cancel checkout
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public cancelCheckout(id: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/payment/checkout/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Verify bid
   * @param requestBody Verify bid request
   * @returns VerifyBidResponse Verify bid response
   * @throws ApiError
   */
  public verifyBid(
    requestBody?: VerifyBidRequest
  ): CancelablePromise<VerifyBidResponse> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/payment/verify-bid",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
