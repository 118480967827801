import { WhoAmIClientResponse } from "@ns/api";
import { useQuery } from "@tanstack/react-query";
import { FC, useMemo, useState, useEffect } from "react";

import { api } from "../api/client-api";
import Loader from "../components/loader.tsx";
import { removeLocalstorageItem } from "../helpers/localstorage.ts";
import useUserStore from "../store/user-store.ts";

import SubHeaderControls from "./sub-header-controls";

interface Props {
  openMobileMenu: () => void;
}

const Header: FC<Props> = ({ openMobileMenu }) => {
  const { setUser } = useUserStore();
  const { data, isLoading: isLoadingBanner } = useQuery({
    queryKey: ["banner"],
    queryFn: () => api.banner.getBanner(),
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingUser } = useQuery({
    queryKey: ["auth"],
    queryFn: () => api.auth.getAuthWhoAmI(),
    onSuccess: (data: WhoAmIClientResponse) => {
      setUser(data);
    },
    onError: () => {
      removeLocalstorageItem("betaModal");
      setUser(null);
    },
    retry: false,
  });

  const isLoading = useMemo(
    () => isLoadingUser || isLoadingBanner,
    [isLoadingBanner, isLoadingUser]
  );

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      const interval = setInterval(() => {
        setFade(true);
        setTimeout(() => {
          setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % data.length);
          setFade(false);
        }, 500); // Duration of the fade-out transition
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="flex w-full items-center justify-center flex-col gap-2 md:gap-[30px]">
      {data && data.length > 0 ? (
        <div className="w-full h-[27px] bg-[#65A8AD99] flex items-center justify-center text-white text-[14px]">
          <span
            className={`message transition-opacity duration-500 ease-in-out ${fade ? "opacity-0" : "opacity-100"}`}
            dangerouslySetInnerHTML={{
              __html: data[currentMessageIndex]?.message || "",
            }}
          ></span>
        </div>
      ) : (
        <div className="h-[27px]"></div>
      )}

      <SubHeaderControls openMobileMenu={openMobileMenu} />
    </div>
  );
};

export default Header;
