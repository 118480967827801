/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from "../models/LoginRequest";
import type { RegisterClientRequest } from "../models/RegisterClientRequest";
import type { RegisterInfluencerRequest } from "../models/RegisterInfluencerRequest";
import type { WhoAmIAdminResponse } from "../models/WhoAmIAdminResponse";
import type { WhoAmIClientResponse } from "../models/WhoAmIClientResponse";
import type { WhoAmIInfluencerResponse } from "../models/WhoAmIInfluencerResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class AuthApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Log in
   * @param requestBody Login body schema
   * @returns any Success login response
   * @throws ApiError
   */
  public login(requestBody?: LoginRequest): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/auth/login",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Wrong credentials`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Logout
   * @returns any Successfully logged out
   * @throws ApiError
   */
  public logout(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/auth/logout",
      errors: {
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check is admin user logged in
   * @returns WhoAmIAdminResponse Check is admin user logged in
   * @throws ApiError
   */
  public getAdminAuthWhoAmI(): CancelablePromise<WhoAmIAdminResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/auth/who-am-i",
      errors: {
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Register
   * @param requestBody Register body schema
   * @returns any Success register response
   * @throws ApiError
   */
  public clientRegistration(
    requestBody?: RegisterClientRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/auth/registration",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Wrong credentials`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check is endUser logged in
   * @returns WhoAmIClientResponse Check is endUser logged in
   * @throws ApiError
   */
  public getAuthWhoAmI(): CancelablePromise<WhoAmIClientResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/auth/who-am-i",
      errors: {
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Influencer registration
   * @param requestBody Register influencer body schema
   * @returns any Success register response
   * @throws ApiError
   */
  public influencerRegistration(
    requestBody?: RegisterInfluencerRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/auth/registration/influencer",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Wrong credentials`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Influencer log in
   * @param requestBody Login body schema
   * @returns any Success login response
   * @throws ApiError
   */
  public influencerLogin(requestBody?: LoginRequest): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/influencer/login",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Wrong credentials`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check is influencer user logged in
   * @returns WhoAmIInfluencerResponse Check is influencer user logged in
   * @throws ApiError
   */
  public getInfluencerWhoAmI(): CancelablePromise<WhoAmIInfluencerResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/influencer/who-am-i",
      errors: {
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }
}
