import { CurrencyResponse } from "@ns/api";
import { WinnerFrame } from "@ns/client-ui";

import flareLeft from "/svg/winner-flare-left.svg";
import flareRight from "/svg/winner-flare-right.svg";
import btcLargeSvg from "/svg/btc-large.svg";
import ethLargeSvg from "/svg/eth-large.svg";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  capitalizeFirstLetter,
  formatDate,
  getCapitalizedFirstLetter,
} from "../../helpers/helpers";

import SpinningCoin from "./spinning-coin.tsx";

const WinnerPreview = ({
  profileImage,
  selectedCurrency,
  userName,
  isSelected,
  countryFlag,
  roundNumber,
  createdAt,
  bid,
  prize,
}: {
  profileImage?: string;
  selectedCurrency: string;
  userName: string;
  isSelected: boolean;
  countryFlag: string;
  roundNumber: string;
  createdAt: string;
  bid: string;
  prize: number;
}) => {
  const currencyImgUrl: { [key: string]: string } = {
    BTC: btcLargeSvg,
    ETH: ethLargeSvg,
  };

  const { t } = useTranslation();
  const { data: currencies } = useQuery<{ items: CurrencyResponse[] }>({
    queryKey: ["currency"],
    queryFn: () => api.resources.getCurrencies(),
  });

  const currency = currencies?.items?.find(
    (currency) => currency.code === selectedCurrency
  );

  const { code, name } = currency || {};

  if (!code || !name) return <></>;

  return (
    <div className="flex flex-col justify-start w-full items-center h-full">
      <div
        className={
          "w-full h-[calc(100% - 50px)] flex flex-col items-center justify-center"
        }
      >
        <div className={"flex flex-col text-white text-center"}>
          <div className={"text-lg avenir-semibold"}>
            {t("common.round")} {roundNumber}
          </div>
          <div className={"text-xs"}>{formatDate(new Date(createdAt))}</div>
        </div>
        <img
          src={countryFlag}
          alt={"flag"}
          className={"absolute top-0 left-0 w-[45px] h-[30px]"}
        />
        <div className="flex flex-col justify-center items-center relative w-[40%]">
          {isSelected && (
            <>
              <img src={flareLeft} className={`absolute top-0 left-0`} />
              <img src={flareRight} className="absolute bottom-0 right-0" />
            </>
          )}
          <WinnerFrame>
            <SpinningCoin
              type={code}
              name={getCapitalizedFirstLetter(name)}
              isSpinning
              profileImage={profileImage}
              alt={"winner frame"}
            />
          </WinnerFrame>
        </div>
        <div
          className={`flex flex-col gap-[27px] items-center justify-center ${
            isSelected ? "visible" : "invisible"
          }`}
        >
          <div className="flex flex-col items-center justify-center">
            <img
              src={currencyImgUrl[code]}
              className={"w-12 h-12 sm:w-16 sm:h-16"}
              alt="currency logo"
            />
            <span className="text-base text-white avenir-semibold sm:text-[22px] pb-[8px] ">
              {capitalizeFirstLetter(name)}
            </span>
            <div className="gradient-underline-blue mb-[8px]" />
            <div className="text-sm sm:text-base flex flex-col">
              <span className="text-white text-center">
                {t("common.congratulations")}{" "}
                <span className="gold-text-clear">@{userName}</span>
                {"!"}
              </span>
              <span className="text-white text-center">
                {t("winnerPreview.youveStealDeal")}{" "}
                <span className="avenir-semibold">
                  {`${prize} ${code}`} {t("winnerPreview.for")} {bid}{" "}
                  {environmentVariables.fiatCurrency}.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerPreview;
