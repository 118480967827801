import {
  GetReferralCodeResponse,
  ReferralResponse,
  WinningReferralResponse,
} from "@ns/api";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

import chevrons from "/svg/chevrons.svg";

import { useOutsideClick } from "../../hooks/use-outside-click.ts";
import { CURRENCY_IMAGE } from "../constants/currency-images.ts";

import ReferralList from "./ui/referral-list.tsx";

enum ReferralTab {
  MY_REFERRALS = "MY_REFERRALS",
  WINNING_REFERRALS = "WINNING_REFERRALS",
}

interface Props {
  referralTab: ReferralTab;
  referrals: ReferralResponse[];
  winningReferrals: WinningReferralResponse[];
  isFetching: boolean;
  lastItemRef: React.Ref<HTMLDivElement>;
  referralCode?: GetReferralCodeResponse;
  isReferralInviteOpen: boolean;
  referralInviteHandler: () => void;
  handleCopy: () => void;
  isTextCopied: boolean;
  onTabChange: (tab: string) => void;
  formatCurrencies: { label: string; value: string; icon: string }[];
  setIsReferralInviteOpen: (val: boolean) => void;
  setCurrencyFilter: (curr: string | undefined) => void;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  setTab: (tab: ReferralTab) => void;
  currencyFilter: string;
}

const FooterRefferalMenu: FC<Props> = ({
  referralTab,
  setTab,
  referrals,
  winningReferrals,
  isFetching,
  lastItemRef,
  referralCode,
  isReferralInviteOpen,
  referralInviteHandler,
  handleCopy,
  isTextCopied,
  onTabChange,
  formatCurrencies,
  setIsReferralInviteOpen,
  setCurrencyFilter,
  isOpen,
  setIsOpen,
  currencyFilter,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  useOutsideClick([containerRef], () => setIsOpen(false));
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCurrencySelectedColor = (currency: string) => {
    if (currencyFilter === currency) return "text-white";
  };

  const handleTabSelectedColor = (tab: ReferralTab) => {
    if (referralTab === tab) return "text-white";
  };

  return (
    <div
      ref={containerRef}
      className={`${isOpen && "h-full"} h-[70%] flex flex-1 flex-col items-end justify-end absolute bottom-0 max-w-[1096px] w-full sm:hidden`}
    >
      <div className="h-[500px] flex w-full absolute bottom-0">
        <div
          className={`flex flex-1 w-full flex-col footer-menu ${
            isOpen ? "footer-menu-active" : "footer-menu"
          } gap-[30px] rounded-tr-[30px] transition-transform duration-300 ease-in-out z-[100]`}
          style={{ transform: isOpen ? "translateY(0)" : "translateY(440px)" }}
        >
          <div
            onClick={toggleMenu}
            className={`absolute cursor-pointer flex justify-center items-center w-[40px] h-[40px] top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full ${
              isOpen
                ? "bg-backgroundSecondary  border border-gray-700"
                : "bg-borderBlue"
            } z-20`}
          >
            <img
              src={chevrons}
              className={`transition-transform duration-300 ease-in-out ${
                isOpen ? "rotate-[0deg]" : "rotate-[180deg]"
              }`}
            />
          </div>

          <div className="h-full flex items-center overflow-hidden gap-[16px] mt-5 p-2 flex-col z-10">
            <div className={"self-baseline flex gap-4"}>
              <span
                onClick={() => setTab(ReferralTab.MY_REFERRALS)}
                className={`text-base avenir-semibold self-start text-textGray3 ${handleTabSelectedColor(ReferralTab.MY_REFERRALS)}`}
              >
                {t("profile.invitedFriends")}
              </span>
              <span
                onClick={() => setTab(ReferralTab.WINNING_REFERRALS)}
                className={`text-base avenir-semibold self-start text-textGray3 ${handleTabSelectedColor(ReferralTab.WINNING_REFERRALS)}`}
              >
                {t("profile.referralWinning")}
              </span>
            </div>
            <div className={"flex gap-4 text-sm w-full text-textGray3"}>
              <div
                onClick={() => setCurrencyFilter("BTC")}
                className={"flex gap-2"}
              >
                <img
                  src={CURRENCY_IMAGE["BTC"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleCurrencySelectedColor("BTC")}>BTC</span>
              </div>
              <div
                className={"flex gap-2"}
                onClick={() => setCurrencyFilter("ETH")}
              >
                <img
                  src={CURRENCY_IMAGE["ETH"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleCurrencySelectedColor("ETH")}>ETH</span>
              </div>
              <div
                className={"flex gap-2"}
                onClick={() => setCurrencyFilter("ETH")}
              >
                <img
                  src={CURRENCY_IMAGE["SOL"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleCurrencySelectedColor("SOL")}>SOL</span>
              </div>
              <div onClick={() => setCurrencyFilter(undefined)}>
                <span
                  className={`${currencyFilter === undefined && "text-white"}`}
                >
                  ALL
                </span>
              </div>
            </div>
            <div className="h-full flex justify-start overflow-auto gap-[16px] flex-col h-auto items-center w-full h-auto">
              <ReferralList
                referralTab={referralTab}
                referrals={referrals}
                winningReferrals={winningReferrals}
                isFetching={isFetching}
                lastItemRef={lastItemRef}
                referralInviteHandler={referralInviteHandler}
                referralCode={referralCode}
                isReferralInviteOpen={isReferralInviteOpen}
                handleCopy={handleCopy}
                isTextCopied={isTextCopied}
                onTabChange={onTabChange}
                formatCurrencies={formatCurrencies}
                setIsReferralInviteOpen={setIsReferralInviteOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterRefferalMenu;
