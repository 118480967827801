import ethSvgSmall from "/svg/eth-svg.svg";
import btcSvgSmall from "/svg/btc-svg.svg";
import solSvgSmall from "/svg/sol-svg.svg";

export type Currency = {
  name: string;
  id: string;
  code: "ETH" | "BTC" | "SOL"; // Add all possible currency codes here
};

export const CURRENCY_IMAGE: { [key in Currency["code"]]: string } = {
  SOL: solSvgSmall,
  ETH: ethSvgSmall,
  BTC: btcSvgSmall,
};
