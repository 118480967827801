import { Button, Dialog } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { usePaymentStore } from "../../store/payment-store.ts";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/animations/loading-animation.json";

export const PaymentProcessingDialogRainbow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isPaymentProcessingRainbowOpen, setIsPaymentProcessingRainbowOpen } =
    usePaymentStore();

  const onClose = () => {
    setIsPaymentProcessingRainbowOpen(false);
  };

  return (
    <Dialog
      className={"px-4"}
      isOpen={isPaymentProcessingRainbowOpen}
      onClose={onClose}
      smallDeviceHeight={"h-fit"}
    >
      <div
        className={
          "w-full text-center flex flex-col justify-center items-center text-white gap-[1rem] px-8 padding-0"
        }
      >
        <span className={"font-semibold text-white text-[18px]"}>
          {t("payment.paymentProcessing")}
        </span>

        <Lottie
          animationData={loadingAnimation}
          loop
          autoPlay
          className="w-[100px] h-[100px]"
        />

        <span className={"text-white text-[16px] text-center"}>
          Your transfer is currently being processed. Once completed, you will
          be notified, and the status will be updated to CONFIRMED in{" "}
          <a
            onClick={() => {
              navigate("/bids");
              onClose();
            }}
            className="underline"
          >
            "My Bids"
          </a>{" "}
          section.
        </span>
        <span className={"text-white text-[20px]"}>
          {t("payment.thankYou")}
        </span>
        <Button variant={"primary"} className={"w-full"} onClick={onClose}>
          {t("common.continue")}
        </Button>
      </div>
    </Dialog>
  );
};
