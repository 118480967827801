import { RoundResponse } from "@ns/api";
import { useMemo, useRef, useState } from "react";

import chevrons from "/svg/chevrons.svg";
import stealDealPng from "/assets/logo.png";

import { useTranslation } from "react-i18next";

import { useOutsideClick } from "../../hooks/use-outside-click.ts";
import { usePagination } from "../../hooks/use-pagination.ts";
import { api } from "../api/client-api.ts";
import { CURRENCY_IMAGE } from "../constants/currency-images.ts";

import FlatList from "./flat-list.tsx";
import AllRoundsCard from "./ui/all-rounds-card.tsx";

const FooterRoundsMenu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currencyFilter, setCurrencyFilter] = useState<string | undefined>("");
  const toggleMenu = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const containerRef = useRef(null);
  useOutsideClick([containerRef], () => setIsOpen(false));

  const {
    data: allRounds,
    lastItemRef,
    isFetchingNextPage,
  } = usePagination<RoundResponse>({
    queryKey: ["allRounds", currencyFilter || ""],
    queryFn: (offset: number, limit: number) =>
      api.round.getRounds("ALL", currencyFilter || "", "", limit, offset),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const renderData = useMemo(() => {
    let rounds = allRounds;
    if (currencyFilter) {
      rounds = allRounds.filter(
        (item) => item.currency.code === currencyFilter
      );
    }

    if (!rounds?.length) {
      return (
        <div className="h-full flex w-full flex-col items-center justify-center">
          <span className="text-[24px] avenir-semibold text-white">
            {t("footerMenu.noRounds")}
          </span>
          <img
            className={"w-[200px] h-auto"}
            src={stealDealPng}
            alt="no bids img"
          />
        </div>
      );
    }

    return (
      <FlatList<RoundResponse>
        data={allRounds}
        isFetching={isFetchingNextPage}
        keyExtractor={(item: RoundResponse) => item.id}
        ref={lastItemRef}
        renderItem={(round: RoundResponse) => (
          <AllRoundsCard
            key={round.id}
            roundNumber={round.number}
            roundStatus={round.status}
            winningBid={round?.winningBid}
            currency={round.currency.code}
            date={round.createdAt}
            prize={round?.prize || 0}
          />
        )}
      />
    );
  }, [currencyFilter, allRounds]);

  const handleSelectedColor = (currency: string) => {
    if (currencyFilter === currency) return "text-white";
  };

  return (
    <div
      ref={containerRef}
      className="h-[500px] flex flex-1 flex-col items-end justify-end absolute bottom-0 max-w-[1096px] w-full sm:hidden"
    >
      <div className="h-full flex w-full absolute bottom-0">
        <div
          className={`flex flex-1 w-full flex-col footer-menu ${
            isOpen ? "footer-menu-active" : "footer-menu"
          } gap-[30px] rounded-tr-[30px] transition-transform duration-300 ease-in-out z-[100]`}
          style={{ transform: isOpen ? "translateY(0)" : "translateY(440px)" }}
        >
          <div
            onClick={toggleMenu}
            className={`absolute cursor-pointer flex justify-center items-center w-[40px] h-[40px] top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full ${
              isOpen
                ? "bg-backgroundDeepTeal border border-gray-700"
                : "bg-borderBlue"
            } z-20`}
          >
            <img
              src={chevrons}
              className={`transition-transform duration-300 ease-in-out ${
                isOpen ? "rotate-[0deg]" : "rotate-[180deg]"
              }`}
            />
          </div>

          <div className="h-full flex items-center overflow-hidden gap-[16px] mt-5 p-2 flex-col z-10">
            <span className="text-base text-white avenir-semibold self-start px-[16px]">
              {t("common.rounds")}
            </span>
            <div className={"flex gap-4 text-sm w-full text-textGray3"}>
              <div
                onClick={() => setCurrencyFilter("BTC")}
                className={"flex gap-2"}
              >
                <img
                  src={CURRENCY_IMAGE["BTC"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleSelectedColor("BTC")}>BTC</span>
              </div>
              <div
                className={"flex gap-2"}
                onClick={() => setCurrencyFilter("ETH")}
              >
                <img
                  src={CURRENCY_IMAGE["ETH"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleSelectedColor("ETH")}>ETH</span>
              </div>
              <div
                className={"flex gap-2"}
                onClick={() => setCurrencyFilter("ETH")}
              >
                <img
                  src={CURRENCY_IMAGE["SOL"]}
                  alt="Currency"
                  className="w-[18px]"
                />
                <span className={handleSelectedColor("SOL")}>SOL</span>
              </div>
              <div onClick={() => setCurrencyFilter("")}>
                <span className={`${!currencyFilter && "text-white"}`}>
                  ALL
                </span>
              </div>
            </div>
            <div className="h-full flex justify-start overflow-auto gap-[16px] flex-col h-auto items-start w-full h-auto">
              {renderData}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterRoundsMenu;
