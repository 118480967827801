import { CreateChargeRequest, RoundResponse } from "@ns/api";
import { Button } from "@ns/client-ui";
import { toast } from "@ns/styles";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { parseUnits } from "viem";
import { useAccount, useWriteContract } from "wagmi";
import { api } from "../../api/client-api";
import { usePaymentStore } from "../../store/payment-store";
import { ChevronDown } from "lucide-react";
// TODO: to env, prevodi
const contractAddresses = {
  56: "0x55d398326f99059ff775485246999027b3197955",
  137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  42161: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
};

const usdtAbi = [
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", type: "bool" }],
    type: "function",
  },
];

interface BidButtonProps {
  round: RoundResponse;
  onClose: () => void;
  placeBid: (data: CreateChargeRequest) => void;
}

const BidButton = ({ round, placeBid, onClose }: BidButtonProps) => {
  const { t } = useTranslation();
  const { bidData, setIsPaymentProcessingRainbowOpen } = usePaymentStore();
  const { data: recipientAddress } = useQuery({
    queryKey: ["merchant-address"],
    queryFn: () => api.payment.getMerchantAddress(),
  });

  const { chainId } = useAccount();
  const amountInWei = parseUnits(round.entryFee, chainId === 56 ? 18 : 6);
  const contractAddress =
    contractAddresses[(chainId as 56 | 137 | 42161) || 56];

  const { writeContractAsync, isLoading } = useWriteContract();

  const onClick = async () => {
    try {
      const data = await writeContractAsync({
        chainId: chainId,
        address: contractAddress as `0x${string}`,
        functionName: "transfer",
        abi: usdtAbi,
        args: [recipientAddress[0].address, amountInWei],
      });
      const bidRequest = {
        ...bidData,
        paymentPlatform: "PARTICLE" as const,
        receiveCurrency: "USDT",
        transactionHash: data,
        merchantAddressId: recipientAddress[0].id,
      };
      placeBid(bidRequest);
      onClose();
      setIsPaymentProcessingRainbowOpen(true);
    } catch (error) {
      console.log("writeContractAsync error", error);
      toast({
        variant: "destructive",
        title: t("common.errors.error"),
        description: "Payment failed",
      });
      Sentry.withScope((scope) => {
        scope.setTag("payment-failed", "basic");
        Sentry.captureException(error);
      });
    }
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-center">
        <ConnectButton.Custom>
          {({ chain, openChainModal }) => {
            return (
              <div className="flex gap-4 pb-8 justify-center items-center">
                <h3 className="avenir-semibold text-[16px]">
                  {t("payment.chooseNetwork")}
                </h3>
                <button
                  onClick={openChainModal}
                  type="button"
                  className="flex gap-2 p-2 h-[50px] w-[165px] rounded-md justify-between items-center bg-[#1B363B]"
                >
                  <div className="flex items-center gap-2">
                    {chain?.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 30,
                          height: 30,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 30, height: 30 }}
                          />
                        )}
                      </div>
                    )}
                    <span className="avenir-semibold">{chain?.name}</span>
                  </div>
                  <ChevronDown />
                </button>
              </div>
            );
          }}
        </ConnectButton.Custom>
      </div>
      <Button onClick={onClick} disabled={isLoading}>
        <span>{isLoading ? t("common.processing") : "Pay"}</span>
      </Button>
    </div>
  );
};

export default BidButton;
