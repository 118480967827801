import { NotificationResponse } from "../components/notification-popup.tsx";
import { translations } from "../utils/translations/notifications.translation.ts";

type EventType =
  | "ROUND_START_BTC"
  | "ROUND_START_ETH"
  | "ROUND_START_SOL"
  | "ROUND_FINISH_BTC"
  | "ROUND_FINISH_ETH"
  | "ROUND_FINISH_SOL"
  | "ROUND_WINNER_BTC"
  | "ROUND_WINNER_ETH"
  | "ROUND_WINNER_SOL"
  | "ROUND_WINNER_REFERRAL_BTC"
  | "ROUND_WINNER_REFERRAL_ETH"
  | "ROUND_WINNER_REFERRAL_SOL"
  | "PAYMENT_SUCCESSFUL";

export const isAppEventType = (type: string) => {
  const validEventTypes: EventType[] = [
    "ROUND_START_BTC",
    "ROUND_START_ETH",
    "ROUND_START_SOL",
    "ROUND_FINISH_BTC",
    "ROUND_FINISH_ETH",
    "ROUND_FINISH_SOL",
    "ROUND_WINNER_BTC",
    "ROUND_WINNER_ETH",
    "ROUND_WINNER_SOL",
    "ROUND_WINNER_REFERRAL_BTC",
    "ROUND_WINNER_REFERRAL_ETH",
    "ROUND_WINNER_REFERRAL_SOL",
    "PAYMENT_SUCCESSFUL",
  ];

  return validEventTypes.includes(type as EventType);
};

const getNumberAfterHash = (text: string) => {
  const match = text.match(/#(\d+)/);
  return match ? parseInt(match[1], 10) : undefined;
};

export const notificationMapper = (
  item: NotificationResponse,
  language: "en" | "de" | "cn" | "tr" | "pt" | "fr"
) => {
  const availableTranslations = translations(getNumberAfterHash(item.title));

  if (
    !availableTranslations[language] ||
    !availableTranslations[language][item.type as EventType]
  ) {
    return availableTranslations["en"][item.type as EventType];
    // throw new Error(
    //   `Translation not found for type: ${item.type}, language: ${language}`
    // );
  }

  return availableTranslations[language][item.type as EventType];
};
