import { CurrencyResponse } from "@ns/api";
import { Modal, Progress } from "@ns/client-ui";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { api } from "../../api/client-api";
import { CURRENCY_IMAGE } from "../../constants/currency-images";
import { giftValidationSchema } from "../../schemas/form/gift-schema";
import { featureFreeBid } from "../../utils/feature-flags/feature-flags.ts";
import ControlledForm from "../form/controlled-form";

import logoButton from "/assets/logo-button.png";
import giftSvg from "/svg/gift.svg";

import CryptoDropdown from "../form/controlled-cryptodropdown.tsx";
import ControlledReferral from "../form/controlled-referal.tsx";
import ReferralInvitePopup from "../popup/referral-invite-popup.tsx";
import CloseBtn from "../svg-components/close-btn.tsx";

const GiftDialogForm = ({ onClose }: { onClose: () => void }) => {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const { data: currencies } = useQuery<{ items: CurrencyResponse[] }>({
    queryKey: ["currencies"],
    queryFn: () => api.resources.getCurrencies(),
  });
  const [currencyName, setCurrencyName] = useState<"BTC" | "ETH">("BTC");
  const [isReferralInviteOpen, setIsReferralInviteOpen] =
    useState<boolean>(false);

  const formatCurrencies = useMemo(
    () =>
      !currencies?.items
        ? []
        : currencies?.items.map((currency) => ({
            label: currency.code.toUpperCase(),
            value: currency.id,
            icon: CURRENCY_IMAGE[currency.code as keyof typeof CURRENCY_IMAGE],
          })),
    [currencies]
  );

  const currencyId = watch("currencyId");

  const { data: freeRound, refetch: refetchFreeRound } = useQuery({
    queryKey: ["freeRound", currencyId],
    queryFn: () => api.orders.checkFreeRound(currencyId),
    enabled: !!currencyId && featureFreeBid,
  });

  const { data: referralCode, refetch: refetchReferralCode } = useQuery({
    queryKey: ["referralCode", currencyId],
    queryFn: () => api.referralCode.getReferralCodeByCurrencyId(currencyId),
    enabled: !!currencyId,
  });

  const onTabChange = useCallback(
    async (newCurrencyId: string) => {
      if (currencyId === newCurrencyId) return;
      setValue("currencyId", newCurrencyId);
      setCurrencyName(
        currencies?.items?.find((currency) => currency.id === newCurrencyId)!
          .code as "BTC" | "ETH"
      );
      await refetchFreeRound();
      await refetchReferralCode();
    },

    [
      refetchReferralCode,
      currencyId,
      setValue,
      refetchFreeRound,
      setCurrencyName,
      currencies,
    ]
  );

  useEffect(() => {
    setValue("currencyId", currencies?.items[0].id);
    refetchFreeRound();
    refetchReferralCode();
  }, [setValue, currencies, refetchFreeRound, refetchReferralCode]);

  const referralHandler = async () => {
    if (!referralCode?.code && !isReferralInviteOpen) {
      setIsReferralInviteOpen(true);
    }
  };

  return (
    <div
      className={`w-full h-full ${featureFreeBid ? "sm:h-[90%] lg:h-[770px]" : "sm:h-fit lg:h-[720px]"} bg-[#001214] relative flex flex-col gap-[32px] justify-start items-center sm:w-[560px] sm:rounded-[16px]`}
    >
      <div className="hidden sm:block border-gradient-blue absolute inset-[1px] sm:border-gradient-blue pointer-events-none sm:rounded-[16px]" />
      <div
        className={
          "flex flex-col justify-center items-center hide-scrollbar overflow-y-auto overflow-x-hidden gap-8 w-full h-full sm:h-fit pt-[80px] pb-[46px] px-[36px] sm:px-[72px] sm:pt-[70px]"
        }
      >
        <div className={"flex flex-col justify-center items-center"}>
          <img
            src={logoButton}
            alt="logo button"
            className="w-[60px] h-[60px] mb-4"
          />
          <div className={"text-white avenir-semibold"}>
            {t("giftDialog.referralProgram")}
          </div>
        </div>
        <CloseBtn
          onClick={onClose}
          className="absolute right-[32px] h-[32px] w-[32px] top-[32px]"
        />

        <div className="w-[304px] flex justify-center items-center">
          {formatCurrencies.length > 0 && (
            <CryptoDropdown
              name="currencyId"
              tabsData={formatCurrencies}
              onTabChange={onTabChange}
            />
          )}
        </div>
        {featureFreeBid && (
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex items-center justify-between w-[185px]">
              <img src={giftSvg} alt="gift" />
              <span className="text-white tracking-widest text-[16px] avenir-semibold">
                {t("common.freeBid").toUpperCase()}
              </span>
              <img src={giftSvg} alt="gift" />
            </div>
            <div className="flex flex-col justify-center items-center text-white text-center gap-[16px]">
              <span>
                {freeRound?.numberOfRounds}/{freeRound?.freeRound || 0}
              </span>
              <Progress
                value={
                  ((freeRound?.numberOfRounds ?? 0) /
                    (freeRound?.freeRound ?? 0)) *
                  100
                }
                className="h-[6px]"
              />
              <span className="text-[14px] text-textGray">
                {t("common.afterPlacing")}
              </span>
            </div>
          </div>
        )}
        <div className="flex max-w-[456px] bg-[#07252A] flex-col justify-center items-center gap-[19px]">
          <div className="relative rounded-[10px] px-[40px] py-[20px] bg-cardFill flex flex-col items-center justify-center w-full text-center gap-[15px]">
            <div className="absolute inset-[1px] border-gradient-blue pointer-events-none rounded-[10px] " />
            <div className="flex items-center justify-center flex-col gap-[10px]">
              <div className="flex gap-2 relative items-center justify-center">
                <img
                  src={CURRENCY_IMAGE[currencyName]}
                  alt="image"
                  className="w-[48px] h-[48px]"
                />
                <div className="flex flex-col gap-[2px] text-left">
                  <div className="text-white text-[16px] avenir-semibold">
                    {t(`giftDialog.referal${currencyName}`)}
                  </div>
                  <div className="text-[#A1A1A1] text-[12px]">
                    {t("giftDialog.get5")} {currencyName}
                  </div>
                </div>
              </div>
            </div>
            <span className="text-[16px] text-white">
              {t(`giftDialog.ifYourFriendUses${currencyName}`)}
            </span>
            <span className="text-[9px] text-textGray">
              *{t("giftDialog.referral")}
            </span>
            <div
              onClick={referralHandler}
              className="flex items-center gap-[10px] w-full relative"
            >
              <ControlledReferral
                referalCode={referralCode?.code}
                icon={CURRENCY_IMAGE[currencyName]}
              />
              {isReferralInviteOpen && (
                <ReferralInvitePopup
                  onClose={() => setIsReferralInviteOpen((val) => !val)}
                />
              )}
            </div>
            <div>
              <span className="text-[12px] text-white">
                {t("giftDialog.copyReferral")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GiftDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <ControlledForm schema={giftValidationSchema}>
      <Modal isOpen={isOpen}>
        <GiftDialogForm onClose={onClose} />
      </Modal>
    </ControlledForm>
  );
};

export default GiftDialog;
