import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { entryFee } from "../../constants/entry-fee";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import btcSvg from "/svg/btc-notification.svg";
import ethSvg from "/svg/eth-notification.svg";
import solSvg from "/svg/sol-notification.svg";
import { RoundResponse } from "@ns/api/src/api/models/RoundResponse.ts";
type Currency = keyof typeof entryFee;

const RewardCard = ({
  data,
  className,
}: {
  data: RoundResponse[] | undefined;
  className?: string;
}) => {
  const { t } = useTranslation();

  const tabsData = {
    BTC: btcSvg,
    ETH: ethSvg,
    SOL: solSvg,
  };
  const [iconName, setIconName] = useState<Currency>("BTC");
  const currency = useWatch({ name: "currency" }) as Currency;

  const selectedCurrencyValues = data?.find(
    (item) => item.currency.code === iconName
  );

  useEffect(() => {
    if (currency) setIconName(currency);
  }, [currency]);

  return (
    <div className="w-full relative h-full">
      <div className="border-gradient-container">
        <div
          className={`w-full lg:w-[165px] pt-2 lg:pt-[22px] pb-[10px] flex rounded-[20px] relative rewardCard text-white px-[20px] items-center justify-center flex-col gap-[15px] avenir-semibold bg-backgroundDeepTeal ${className}`}
        >
          <div
            className={
              "mt-6 flex w-full gap-4 lg:flex-col lg:justify-center lg:items-center"
            }
          >
            <div className="absolute top-[-21px] z-[2] left-1/2 transform translate-x-[-50%]">
              <img
                src={tabsData[iconName]}
                alt={`${currency}`}
                className="animate-spinner w-[42px] h-[42px]"
              />
            </div>
            <div
              className={
                "w-[50px] h-[50px] bg-background rounded-[50%] absolute top-[-25px] z-[1] left-1/2 transform translate-x-[-50%]"
              }
            />
            <div
              className={
                "w-full flex gap-4 flew-row justify-between text-center md:flex-col"
              }
            >
              <span className="text-gray-400 text-sm">
                {t("common.entryFee")}
              </span>
              <span className={"text-sm text-center"}>
                {selectedCurrencyValues?.entryFee}{" "}
                {environmentVariables.fiatCurrency}
              </span>
            </div>
          </div>
          <div className="hidden lg:block bg-gradientGoldShade h-[1px] w-full" />
          <div
            className={
              "flex justify-between w-full gap-[10px] justify-center items-center reward-container-padding"
            }
          >
            <span className="text-sm text-gray-400">{t("common.reward")}</span>
            <div className="flex items-center gap-[6px]">
              <span className="text-sm">{selectedCurrencyValues?.prize}</span>
              <span className="text-sm">{currency}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardCard;
