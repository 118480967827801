import { useTranslation } from "react-i18next";

import useScreenSize from "../../../hooks/use-screen-size.ts";
import { CURRENCY_IMAGE } from "../../constants/currency-images";
import CircleBtc from "../svg-components/circle-btc.tsx";
import CircleEth from "../svg-components/circle-eth.tsx";
import CircleSol from "../svg-components/circle-sol.tsx";

export type RoundProgressData = {
  id: string;
  round: number;
  progress: string;
  currency: "BTC" | "ETH" | "SOL";
  hasBlur: boolean;
};

const CircleData = ({
  id,
  round,
  progress,
  currency,
  hasBlur,
}: RoundProgressData) => {
  const { t } = useTranslation();

  const interpolateColor = (
    color1: string,
    color2: string,
    factor: number,
  ): string => {
    const hexToRgb = (hex: string) => {
      const bigint = parseInt(hex.slice(1), 16);
      return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
    };

    const rgbToHex = (rgb: number[]) => {
      return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;
    };

    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    const resultRgb = rgb1.map((value, index) => {
      return Math.round(value + factor * (rgb2[index] - value));
    });

    return rgbToHex(resultRgb);
  };

  const handleColor = (): string => {
    const color1 = "#00FDF6";
    const color2 = "#00D409";
    const color3 = "#EAE201";
    const color4 = "#FE6D00";
    const color5 = "#DF000E";
    const prog = Number(progress);

    if (prog < 25) {
      const factor = (prog - 5) / 30;
      return interpolateColor(color1, color2, Math.max(0, Math.min(1, factor)));
    }
    if (prog < 50) {
      const factor = (prog - 25) / 30;
      return interpolateColor(color2, color3, Math.max(0, Math.min(1, factor)));
    }
    if (prog < 75) {
      const factor = (prog - 50) / 30;
      return interpolateColor(color3, color4, Math.max(0, Math.min(1, factor)));
    }
    if (prog < 100) {
      const factor = (prog - 75) / 40;
      return interpolateColor(color4, color5, Math.max(0, Math.min(1, factor)));
    }
    return color5;
  };

  const { height } = useScreenSize();

  return (
    <div
      className={`h-full flex relative flex-col items-center ${hasBlur && "blur-sm"}`}
    >
      <div
        className={
          "gap-2 absolute top-1/2 transform -translate-y-1/2 flex flex-col justify-center items-center"
        }
      >
        <div className="w-4 h-4 lg:w-auto lg:h-auto">
          <img src={CURRENCY_IMAGE[currency]} />
        </div>
        <div className="flex flex-col justify-center items-center ">
          <span
            className={`${height < 600 && "text-[10px] xl:text-[14px] 2xl:text-[28px]"} text-[12px] md:text-[16px] avenir-semibold 2xl:text-[34px]`}
            style={{ color: handleColor() }}
          >{`${progress}%`}</span>
          <span
            className={`${height < 600 && "text-[10px] xl:text-[14px]"} text-[12px] text-white xl:text-[16px]`}
          >
            {t("common.round")} {round}
          </span>
        </div>
      </div>
      {currency === "BTC" && <CircleBtc color={handleColor()} id={id} />}
      {currency === "ETH" && <CircleEth color={handleColor()} id={id} />}
      {currency === "SOL" && <CircleSol color={handleColor()} id={id} />}
      {/*<div className="hidden xl:flex relative flex-col items-center justify-center pt-[2rem]">*/}
      {/*  <span className="text-white avenir-semibold text-[16px]">*/}
      {/*    {t("common.round")} #{round}*/}
      {/*  </span>*/}
      {/*  <span className="text-white avenir-semibold text-[16px]">*/}
      {/*    {Number(progress) === 100*/}
      {/*      ? t("common.isClosed")*/}
      {/*      : t("common.inProgress")}*/}
      {/*  </span>*/}
      {/*</div>*/}
    </div>
  );
};

export default CircleData;
