import {
  CheckReferralCodeResponse,
  CreateReferralRequest,
  UseCouponRequest,
} from "@ns/api";
import { Button, Dialog, TooltipBidDialog } from "@ns/client-ui";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dollarGoldSvg from "/svg/dollarGold.svg";
import giftSvg from "/svg/gift.svg";
import logoSvg from "/svg/small-logo.svg";

import { api } from "../../api/client-api.ts";
import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import { addReferralSchema } from "../../schemas/form/add-referral-schema.ts";
import { placeBidFormNames } from "../../schemas/form/place-bid-schema.ts";
import { featureFreeBid } from "../../utils/feature-flags/feature-flags.ts";
import ControlledDropdown from "../form/controlled-dropdown.tsx";
import ControlledForm from "../form/controlled-form.tsx";
import ControlledPlaceABidInput from "../form/controlled-place-a-bid-input.tsx";
import InputPromoCode from "../form/input-promo-code";
import AddReferralButton from "../svg-components/add-referral-button.tsx";

const BidDialog = ({
  isOpen,
  onClose,
  onSuccess,
  roundId,
  entryFee,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  roundId?: string;
  entryFee: number;
}) => {
  const {
    getValues,
    watch,
    reset,
    setValue,
    formState: { isValid },
  } = useFormContext();

  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isDropdownInputDisplayed, setIsDropdownInputDisplayed] =
    useState<boolean>(false);

  const toggleIsUpdated = () => {
    setIsUpdated((isUpdated) => !isUpdated);
  };
  const { data: round } = useQuery({
    queryKey: ["round", roundId],
    queryFn: () => api.round.getRoundById(roundId!),
    enabled: !!roundId,
  });

  const { data: freeRound } = useQuery({
    queryKey: ["freeRound", round?.currency?.id],
    queryFn: () => api.orders.checkFreeRound(round!.currency!.id!),
    enabled: !!round?.currency?.id && featureFreeBid,
  });

  const { data: availableCoupons } = useQuery({
    queryKey: ["availableCoupons"],
    queryFn: () => api.coupons.checkAvailableCouponsExist(),
    enabled: !!round?.currency?.id,
  });

  const { data: referredBy, refetch } = useQuery({
    queryKey: ["referredBy", round?.currency?.id],
    queryFn: () => api.referral.getReferredBy(round!.currency!.id!),
    enabled: !!round?.currency?.id,
  });

  useEffect(() => {
    if (isUpdated) {
      refetch();
      setIsUpdated(false);
    }
  }, [isUpdated, refetch, setIsUpdated, isOpen]);

  const selectedReferral = watch("referralId");

  useEffect(() => {
    if (selectedReferral !== "1") {
      setValue("referralId", selectedReferral);
    } else {
      setValue("referralId", undefined);
    }
  }, [setValue, selectedReferral]);

  const {
    mutate: checkPromoCode,
    data: discountData,
    isIdle,
  } = useMutation({
    mutationFn: (data: UseCouponRequest) => api.payment.useCoupon(data),
  });

  const promoCode = useWatch({ name: "promoCode" });
  const bidValue = useWatch({ name: "bid" });

  const { t } = useTranslation();
  const { discountedPrice, discount } = useMemo(() => {
    const referralDiscount = !selectedReferral ? 0 : 5;

    const promoDiscount = discountData?.discount ?? 0;

    const discount = promoDiscount + referralDiscount;

    return {
      discountedPrice: entryFee - (entryFee * discount) / 100,
      discount,
    };
  }, [discountData, entryFee, selectedReferral]);

  useEffect(() => {
    if (!roundId || !promoCode) return;
    const timeout = setTimeout(() => {
      checkPromoCode({ roundId, promoCode });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [roundId, checkPromoCode, promoCode]);

  useEffect(() => {
    setValue("currencyBid", getValues("currency"));
  }, [getValues, setValue]);

  const isFree = useMemo(() => {
    return freeRound?.isFree ?? false;
  }, [freeRound]);

  const formatedReferredBy = useMemo(() => {
    const formatedRefferedBy = (referredBy?.items || []).map((referredBy) => {
      return {
        value: referredBy.id,
        label: referredBy.name,
      };
    });
    return [
      ...formatedRefferedBy,
      {
        value: "1",
        label: t("dashboard.dialog.addNew"),
        icon: <AddReferralButton />,
      },
    ];
  }, [referredBy]);

  const isFreeRoundAvailable = useMemo(() => {
    return featureFreeBid && isFree;
  }, [isFree]);

  if (!round) {
    return <></>;
  }

  return (
    <Dialog
      title={`${round?.currency?.code} Round #${round?.number}`}
      progress={round?.progress ? Number(round?.progress) : 0}
      isOpen={isOpen}
      successBtnText={t("common.placeBid")}
      onClose={() => {
        onClose();
        reset();
      }}
      onSuccess={() => {
        onSuccess();
        reset();
      }}
      titleIcon={CURRENCY_IMAGE[round.currency.code as "ETH" | "BTC" | "SOL"]}
      isValid={isValid}
    >
      <div className="flex flex-col items-center justify-start w-[343px] gap-[16px] container-90">
        <div className="flex flex-col items-start justify-center pb-[16px] w-full">
          {isFreeRoundAvailable && (
            <span
              className={
                "text-white text-[20px] avenir-semibold text-center pb-[25px]"
              }
            >
              {t("dashboard.dialog.useFree")}
            </span>
          )}
          <div className="entryFeeCard flex items-center h-[44px] justify-between w-full p-[20px] entry-fee-container">
            <div className="flex items-center gap-[16px]">
              {!isFreeRoundAvailable ? (
                <img src={dollarGoldSvg} alt="dollar" />
              ) : (
                <img src={giftSvg} alt="gift" />
              )}
              <span className="text-white text-[20px] avenir-semibold">
                {!isFreeRoundAvailable
                  ? t("dashboard.dialog.entryFee")
                  : t("dashboard.dialog.freeRound")}
              </span>
            </div>
            <span className="text-[20px] text-priceColor avenir-semibold">
              {!isFreeRoundAvailable ? round.entryFee : 0}{" "}
              {environmentVariables.fiatCurrency}
            </span>
          </div>
        </div>
        <div className="relative w-full">
          <div
            style={{ zIndex: -1 }}
            className="rounded-lg sm:rounded-2xl absolute inset-0 card-border h-full w-full"
          ></div>
          <div className="bg-[#07252A] text-center z-10 p-4 entry-fee-container w-full flex flex-col gap-[16px] border text-card-foreground shadow-sm h-fit border-none sm:rounded-2xl">
            <div className="flex justify-center items-center">
              <img className="h-[40px] w-[40px]" src={logoSvg} alt="dolar" />
            </div>
            <div className="w-full flex flex-col gap-[6px]">
              <ControlledPlaceABidInput
                name={placeBidFormNames.bid}
                id={placeBidFormNames.bid}
                placeholder={t("dashboard.dialog.enterBidValue")}
                currency={round.currency.code}
                className="bg-[#001214]"
              />
              <span className="text-[12px] text-center text-textGray self-center">
                {t("dashboard.dialog.noteBid", { entryFee: round?.entryFee })}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="gold-text-clear avenir-semibold">
                {t("dashboard.dialog.bid")}:
              </span>
              <span className="gold-text-clear avenir-semibold">
                {bidValue || 0} {environmentVariables.fiatCurrency}
              </span>
            </div>
          </div>
        </div>

        {!isFreeRoundAvailable && (
          <div className="flex flex-col items-start justify-center w-full gap-[15px]">
            {availableCoupons?.isAvailable && (
              <div className="flex flex-row w-full items-center justify-between gap-[8px]">
                <span className="text-white text-[16px] flex gap-2 items-center avenir-semibold">
                  <span>🎁</span> {t("dashboard.dialog.promoCode")}:
                  <TooltipBidDialog
                    position="top"
                    text="Apply a promo code to enjoy a discount on the bidding fee."
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0.261719C6.51664 0.261719 5.06659 0.701586 3.83323 1.5257C2.59986 2.34981 1.63856 3.52115 1.07091 4.89159C0.50325 6.26204 0.354725 7.77004 0.644114 9.22489C0.933503 10.6798 1.64781 12.0161 2.6967 13.065C3.7456 14.1139 5.08197 14.8282 6.53682 15.1176C7.99168 15.407 9.49968 15.2585 10.8701 14.6908C12.2406 14.1232 13.4119 13.1619 14.236 11.9285C15.0601 10.6951 15.5 9.24508 15.5 7.76172C15.4979 5.77324 14.7071 3.8668 13.301 2.46074C11.8949 1.05467 9.98848 0.263819 8 0.261719ZM7.71154 3.72326C7.8827 3.72326 8.05001 3.77401 8.19232 3.8691C8.33463 3.96419 8.44555 4.09934 8.51105 4.25747C8.57655 4.4156 8.59369 4.5896 8.5603 4.75747C8.52691 4.92534 8.44449 5.07953 8.32346 5.20056C8.20243 5.32159 8.04824 5.40401 7.88037 5.4374C7.7125 5.47079 7.5385 5.45365 7.38037 5.38815C7.22224 5.32265 7.08709 5.21173 6.992 5.06942C6.89691 4.92711 6.84616 4.7598 6.84616 4.58864C6.84616 4.35913 6.93733 4.13901 7.09962 3.97672C7.26191 3.81443 7.48203 3.72326 7.71154 3.72326ZM8.57692 11.8002C8.27091 11.8002 7.97742 11.6786 7.76103 11.4622C7.54464 11.2458 7.42308 10.9524 7.42308 10.6463V7.76172C7.27007 7.76172 7.12333 7.70093 7.01513 7.59274C6.90694 7.48455 6.84616 7.3378 6.84616 7.18479C6.84616 7.03178 6.90694 6.88504 7.01513 6.77685C7.12333 6.66865 7.27007 6.60787 7.42308 6.60787C7.7291 6.60787 8.02258 6.72944 8.23897 6.94583C8.45536 7.16221 8.57692 7.4557 8.57692 7.76172V10.6463C8.72993 10.6463 8.87668 10.7071 8.98487 10.8153C9.09306 10.9235 9.15385 11.0702 9.15385 11.2233C9.15385 11.3763 9.09306 11.523 8.98487 11.6312C8.87668 11.7394 8.72993 11.8002 8.57692 11.8002Z"
                        fill="white"
                        fill-opacity="0.5"
                      />
                    </svg>
                  </TooltipBidDialog>
                </span>
                <div className={"flex w-[145px] flex-col gap-[6px]"}>
                  <InputPromoCode
                    name={"promoCode"}
                    placeholder={t("dashboard.dialog.enterPromoCode")}
                    codeCorrect={!!discountData}
                    loaded={!isIdle}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col items-start justify-center w-full gap-[15px]">
              <span className="text-white text-[16px] flex items-center gap-2 avenir-semibold">
                <span>👤 </span>
                {t("dashboard.dialog.referralProgram")}:
                <TooltipBidDialog
                  position="top"
                  title="Add Referral Code"
                  text="Get an additional 5% discount on the bidding fee."
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0.261719C6.51664 0.261719 5.06659 0.701586 3.83323 1.5257C2.59986 2.34981 1.63856 3.52115 1.07091 4.89159C0.50325 6.26204 0.354725 7.77004 0.644114 9.22489C0.933503 10.6798 1.64781 12.0161 2.6967 13.065C3.7456 14.1139 5.08197 14.8282 6.53682 15.1176C7.99168 15.407 9.49968 15.2585 10.8701 14.6908C12.2406 14.1232 13.4119 13.1619 14.236 11.9285C15.0601 10.6951 15.5 9.24508 15.5 7.76172C15.4979 5.77324 14.7071 3.8668 13.301 2.46074C11.8949 1.05467 9.98848 0.263819 8 0.261719ZM7.71154 3.72326C7.8827 3.72326 8.05001 3.77401 8.19232 3.8691C8.33463 3.96419 8.44555 4.09934 8.51105 4.25747C8.57655 4.4156 8.59369 4.5896 8.5603 4.75747C8.52691 4.92534 8.44449 5.07953 8.32346 5.20056C8.20243 5.32159 8.04824 5.40401 7.88037 5.4374C7.7125 5.47079 7.5385 5.45365 7.38037 5.38815C7.22224 5.32265 7.08709 5.21173 6.992 5.06942C6.89691 4.92711 6.84616 4.7598 6.84616 4.58864C6.84616 4.35913 6.93733 4.13901 7.09962 3.97672C7.26191 3.81443 7.48203 3.72326 7.71154 3.72326ZM8.57692 11.8002C8.27091 11.8002 7.97742 11.6786 7.76103 11.4622C7.54464 11.2458 7.42308 10.9524 7.42308 10.6463V7.76172C7.27007 7.76172 7.12333 7.70093 7.01513 7.59274C6.90694 7.48455 6.84616 7.3378 6.84616 7.18479C6.84616 7.03178 6.90694 6.88504 7.01513 6.77685C7.12333 6.66865 7.27007 6.60787 7.42308 6.60787C7.7291 6.60787 8.02258 6.72944 8.23897 6.94583C8.45536 7.16221 8.57692 7.4557 8.57692 7.76172V10.6463C8.72993 10.6463 8.87668 10.7071 8.98487 10.8153C9.09306 10.9235 9.15385 11.0702 9.15385 11.2233C9.15385 11.3763 9.09306 11.523 8.98487 11.6312C8.87668 11.7394 8.72993 11.8002 8.57692 11.8002Z"
                      fill="white"
                      fill-opacity="0.5"
                    />
                  </svg>
                </TooltipBidDialog>
              </span>
              {formatedReferredBy && !isDropdownInputDisplayed ? (
                <div className="flex flex-row justify-between w-full">
                  <div className={"flex w-[260px]"}>
                    <span className="text-[16px] avenir-semibold text-textGray self-center">
                      <span className={"gold-text-clear"}>{`5% ${t(
                        "dashboard.dialog.off"
                      )}`}</span>{" "}
                      <span className="text-white avenir-semibold text-[16px]">
                        {t("dashboard.dialog.fromReferral")}
                      </span>
                    </span>
                  </div>
                  <ControlledDropdown
                    name={"referralId"}
                    options={formatedReferredBy}
                    showInput={setIsDropdownInputDisplayed}
                  />
                </div>
              ) : (
                <ControlledForm schema={addReferralSchema}>
                  <AddReferral
                    onCancel={() => {
                      setValue("referralId", undefined);
                      setIsDropdownInputDisplayed(false);
                    }}
                    setIsUpdated={toggleIsUpdated}
                    currencyId={round.currency.id}
                  />
                </ControlledForm>
              )}
            </div>
          </div>
        )}

        {(discountData || selectedReferral) && selectedReferral !== "1" && (
          <div className="flex flex-col w-full border-t border-t-solid border-t-borderBlue border-b border-b-solid pb-[15px] pt-[15px] gap-[6px] border-borderBlue items-center text-white">
            <div className="flex flex-row w-full justify-between">
              <span>{`${t("dashboard.dialog.subtotal")}:`}</span>
              <span>{`${round?.entryFee} ${environmentVariables.fiatCurrency}`}</span>
            </div>
            <div className="flex flex-row w-full justify-between">
              <span>{`${t("dashboard.dialog.discount")}:`}</span>
              <span>{discount}%</span>
            </div>
          </div>
        )}
        {(discountData || selectedReferral) && selectedReferral !== "1" && (
          <div className="flex flex-col items-start justify-center pb-[16px] w-full gap-[6px]">
            <div className="flex justify-between w-full items-center">
              <span className="text-white text-[16px] avenir-semibold">
                {t("dashboard.dialog.total")}:
              </span>
              <span className="text-red-400 line-through text-[16px] avenir-semibold">
                {entryFee} {environmentVariables.fiatCurrency}
              </span>
            </div>
            <span className="text-white text-[18px] avenir-semibold self-end">
              {discountedPrice} {environmentVariables.fiatCurrency}
            </span>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export const AddReferral = ({
  setIsUpdated,
  disabled,
  onCancel,
  currencyId,
}: {
  setIsUpdated?: () => void;
  disabled?: boolean;
  onCancel?: () => void;
  currencyId?: string;
}) => {
  const { setError, handleSubmit, setValue } = useFormContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: checkReferralCodeMutation,
    isIdle,
    data: checkReferralCode,
  } = useMutation({
    mutationFn: (data: string) =>
      api.referralCode.checkReferralCode(data, currencyId),
    onSuccess: (response: CheckReferralCodeResponse) => {
      if (!response.isValid) {
        setError("code", {
          message: t("profile.dialog.referral.invalidCode"),
        });
      }
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("check-referral-code-mutation", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
      setError("code", { message: t("profile.dialog.referral.invalidCode") });
    },
  });

  const { mutate: addReferralCodeMutation } = useMutation({
    mutationFn: (data: CreateReferralRequest) =>
      api.referral.createReferral(data),
    onSuccess: async () => {
      setValue("code", "");
      if (setIsUpdated) {
        setIsUpdated();
      }
      await queryClient.invalidateQueries({ queryKey: ["referredBy"] });
      if (onCancel) {
        onCancel();
      }
    },
    onError: (error, variables) => {
      Sentry.withScope((scope) => {
        scope.setTag("add-referral-code", "basic");
        scope.setExtra("data", variables);
        Sentry.captureException(error);
      });
    },
  });

  const referralCode = useWatch({ name: "code" });

  useEffect(() => {
    if (!referralCode) {
      return;
    }

    const timeout = setTimeout(() => {
      checkReferralCodeMutation(referralCode);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [referralCode, checkReferralCodeMutation]);

  const onSubmit = handleSubmit((data) =>
    addReferralCodeMutation({ code: data.code })
  );

  return (
    <div className={"flex w-full flex-row gap-[0.25rem] relative"}>
      {onCancel && (
        <div
          onClick={onCancel}
          className={
            "cursor-pointer underline text-white absolute top-[-26px] right-[5px]"
          }
        >
          {t("common.cancel")}
        </div>
      )}
      <InputPromoCode
        name={"code"}
        placeholder={t("dashboard.dialog.enterReferralCode")}
        codeCorrect={checkReferralCode?.isValid ?? false}
        loaded={!isIdle}
        hasPasteIcon={true}
        showErrorMessage={true}
        disabled={disabled}
      />
      <Button onClick={onSubmit} disabled={!checkReferralCode?.isValid}>
        <span>{t("dashboard.dialog.add")}</span>
      </Button>
    </div>
  );
};

export default BidDialog;
