export const SolClosed = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="50"
        height="50"
        rx="25"
        fill="url(#paint0_linear_20012249_6842)"
        fill-opacity="0.2"
      />
      <circle cx="25.0002" cy="24.9997" r="18.2692" fill="black" />
      <path
        d="M18.0562 29.3294C18.1828 29.2028 18.3569 29.1289 18.5415 29.1289H35.2845C35.5904 29.1289 35.7434 29.4982 35.5271 29.7144L32.2197 33.0219C32.0931 33.1485 31.919 33.2223 31.7344 33.2223H14.9914C14.6854 33.2223 14.5325 32.8531 14.7488 32.6368L18.0562 29.3294Z"
        fill="url(#paint1_linear_20012249_6842)"
      />
      <path
        d="M18.0562 16.9797C18.1881 16.8531 18.3622 16.7793 18.5415 16.7793H35.2845C35.5904 16.7793 35.7434 17.1485 35.5271 17.3648L32.2197 20.6723C32.0931 20.7989 31.919 20.8727 31.7344 20.8727H14.9914C14.6854 20.8727 14.5325 20.5035 14.7488 20.2872L18.0562 16.9797Z"
        fill="url(#paint2_linear_20012249_6842)"
      />
      <path
        d="M32.2197 23.1145C32.0931 22.9879 31.919 22.9141 31.7344 22.9141H14.9914C14.6854 22.9141 14.5325 23.2833 14.7488 23.4996L18.0562 26.807C18.1828 26.9336 18.3569 27.0075 18.5415 27.0075H35.2845C35.5904 27.0075 35.7434 26.6382 35.5271 26.422L32.2197 23.1145Z"
        fill="url(#paint3_linear_20012249_6842)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20012249_6842"
          x1="50"
          y1="25"
          x2="0"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEEEEE" />
          <stop offset="0.405" stop-color="#888888" stop-opacity="0.15" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20012249_6842"
          x1="-23.1946"
          y1="13.6739"
          x2="-23.6401"
          y2="36.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFA3" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20012249_6842"
          x1="-28.2613"
          y1="11.0279"
          x2="-28.7067"
          y2="33.444"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFA3" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20012249_6842"
          x1="-25.7441"
          y1="12.342"
          x2="-26.1895"
          y2="34.7581"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.37" stop-color="#00FFA3" />
          <stop offset="0.665" stop-color="#DC1FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
