export enum EventDetail {
  SEND_BATCH_NOTIFICATIONS = "SEND_BATCH_NOTIFICATIONS",
  SEND_TELEGRAM_MESSAGE = "SEND_TELEGRAM_MESSAGE",
  ROUND_FINISHED_BTC = "ROUND_FINISHED_BTC",
  ROUND_STARTED_BTC = "ROUND_STARTED_BTC",
  ROUND_UPDATE_BTC = "ROUND_UPDATE_BTC",
  ROUND_FINISHED_ETH = "ROUND_FINISHED_ETH",
  ROUND_STARTED_ETH = "ROUND_STARTED_ETH",
  ROUND_UPDATE_ETH = "ROUND_UPDATE_ETH",
  ROUND_FINISHED_SOL = "ROUND_FINISHED_SOL",
  ROUND_STARTED_SOL = "ROUND_STARTED_SOL",
  ROUND_UPDATE_SOL = "ROUND_UPDATE_SOL",
  BIDS = "BIDS",
  NOTIFICATIONS = "NOTIFICATIONS",
  BANNER = "BANNER",
  USER_UPDATE = "USER_UPDATE",
  WINNER = "WINNER",
  REFERRAL_WINNER = "REFERRAL_WINNER",
  REFERRAL_UPDATE = "REFERRAL_UPDATE",
  REFERRAL_UNLOCKED = "REFERRAL_UNLOCKED",
}
