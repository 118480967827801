import React from 'react';

interface TooltipProps {
  position?: 'top' | 'right' | 'bottom' | 'left';
  title?: string;
  text: string;
  children: React.ReactNode;
}

const TooltipBidDialog = ({ position = 'top', title, text, children }: TooltipProps) => {
  const getTooltipPosition = () => {
    switch (position) {
      case 'top':
        return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
      case 'right':
        return 'left-full top-1/2 transform -translate-y-1/2 ml-2';
      case 'bottom':
        return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
      case 'left':
        return 'right-full top-1/2 transform -translate-y-1/2 mr-2';
      default:
        return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
    }
  };

  return (
    <div className="relative inline-block group cursor-pointer">
      {children}
      <div
        className={`absolute whitespace-normal p-2 bg-white text-black text-sm text-center shadow-lg rounded-md invisible group-hover:visible transition-opacity duration-300 z-50 ${getTooltipPosition()}`}
        style={{ width: '280px', minHeight: '56px' }}
      >
        {title && <div className="avenir-semibold mb-1">{title}</div>}
        <p className="text-sm text-muted-foreground w-full">{text}</p>
      </div>
    </div>
  );
};

export default TooltipBidDialog;
