import { TFunction } from "i18next";

import { NotificationType } from "../enums/notification-type.enum.ts";
import { BidTab, ReferralTab } from "../pages/auth/user-page.tsx";

export const notificationRouteHelper = (type: string, t: TFunction) => {
  switch (type) {
    case NotificationType.ROUND_START_BTC:
      return {
        text: t("notification.placeBid"),
        route: "/",
        tab: "BTC",
        selectedItem: undefined,
      };
    case NotificationType.ROUND_START_ETH:
      return {
        text: t("notification.placeBid"),
        route: "/",
        tab: "ETH",
        selectedItem: undefined,
      };
    case NotificationType.ROUND_START_SOL:
      return {
        text: t("notification.placeBid"),
        route: "/",
        tab: "SOL",
        selectedItem: undefined,
      };
    case NotificationType.ROUND_FINISH_BTC:
      return {
        text: t("notification.winnersList"),
        route: "/winners",
        tab: "winners",
        selectedItem: "winners",
      };
    case NotificationType.ROUND_FINISH_ETH:
      return {
        text: t("notification.winnersList"),
        route: "/winners",
        tab: "winners",
        selectedItem: "winners",
      };
    case NotificationType.ROUND_FINISH_SOL:
      return {
        text: t("notification.winnersList"),
        route: "/winners",
        tab: "winners",
        selectedItem: "winners",
      };
    case NotificationType.ROUND_WINNER_ETH:
      return {
        text: t("notification.seeReward"),
        route: "/bids",
        tab: BidTab.WINNING_BIDS,
        selectedItem: "myBids",
      };
    case NotificationType.ROUND_WINNER_BTC:
      return {
        text: t("notification.seeReward"),
        route: "/bids",
        tab: BidTab.WINNING_BIDS,
        selectedItem: "myBids",
      };
    case NotificationType.ROUND_WINNER_SOL:
      return {
        text: t("notification.seeReward"),
        route: "/bids",
        tab: BidTab.WINNING_BIDS,
        selectedItem: "myBids",
      };
    case NotificationType.ROUND_WINNER_REFERRAL_BTC:
      return {
        text: t("notification.seeReward"),
        route: "/referrals",
        tab: ReferralTab.WINNING_REFERRALS,
        selectedItem: "referral",
      };
    case NotificationType.ROUND_WINNER_REFERRAL_ETH:
      return {
        text: t("notification.seeReward"),
        route: "/referrals",
        tab: ReferralTab.WINNING_REFERRALS,
        selectedItem: "referral",
      };
    case NotificationType.ROUND_WINNER_REFERRAL_SOL:
      return {
        text: t("notification.seeReward"),
        route: "/referrals",
        tab: ReferralTab.WINNING_REFERRALS,
        selectedItem: "referral",
      };
    case NotificationType.PAYMENT_SUCCESSFUL:
      return {
        text: t("notification.seeBids"),
        route: "/bids",
        tab: BidTab.MY_BIDS,
        selectedItem: "myBids",
      };
    default:
      return undefined;
  }
};
