import { CardContent, CardHeader } from "@ns/client-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { formatDate } from "../helpers/helpers.ts";
import {
  isAppEventType,
  notificationMapper,
} from "../helpers/notification-content.mapper.ts";
import { notificationIconHelper } from "../helpers/notification-icon-helper.ts";
import { notificationRouteHelper } from "../helpers/notification-route-helper.ts";
import { useDialogContext } from "../store/dialog-store.ts";
import useUserStore from "../store/user-store.ts";

import { NotificationResponse } from "./notification-popup.tsx";

export const NotificationCard = ({
  item,
  handleCloseNotification,
}: {
  item: NotificationResponse;
  handleCloseNotification: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedTab } = useDialogContext();
  const { setSelectedItem } = useUserStore();

  const notificationRoute = notificationRouteHelper(item.type, t);
  const isAppNotification = isAppEventType(item.type);

  let title;
  let text;

  if (isAppNotification) {
    // @ts-expect-error - TS doesn't know that isAppNotification is true
    const notification = notificationMapper(item, i18n.language);
    title = notification.title;
    text = notification.text;
  } else {
    title = item.title;
    text = item.notification;
  }
  return (
    <div className="flex gap-[14px] w-full pt-2 pb-4 items-center border-b border-tabBorderColor">
      <img
        src={notificationIconHelper(item.type)}
        className="w-[40px] h-[40px]"
      />
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full justify-between">
          <CardHeader className="p-0">
            <div className="flex flex-col">
              <span className="text-white text-[12px] avenir-semibold text-left">
                {title}
              </span>
            </div>
          </CardHeader>
          <div className="space-y-1.5">
            <span className="text-textGray text-[12px] text-left">
              {formatDate(new Date(item.createdAt))}
            </span>
          </div>
        </div>
        <CardContent className="p-0">
          <div className="flex flex-col gap-[0.5rem]">
            <span className="text-white text-[10px] text-left">{text}</span>
            {notificationRoute && (
              <button
                style={{ backgroundColor: "#D9D9D91A" }}
                className={`w-fit h-[1rem] inline-flex items-center justify-center rounded px-[0.5rem]`}
                onClick={
                  notificationRoute?.route
                    ? () => {
                        navigate(notificationRoute.route);
                        if (notificationRoute?.tab) {
                          setSelectedTab(notificationRoute.tab);
                        }
                        setSelectedItem(notificationRoute.selectedItem);
                        handleCloseNotification();
                      }
                    : undefined
                }
              >
                <span className={"text-white text-[10px] text-center"}>
                  {notificationRoute.text}
                </span>
              </button>
            )}
          </div>
        </CardContent>
      </div>
    </div>
  );
};
