export enum NotificationType {
  ROUND_START_BTC = "ROUND_START_BTC",
  ROUND_START_ETH = "ROUND_START_ETH",
  ROUND_START_SOL = "ROUND_START_SOL",
  ROUND_FINISH_BTC = "ROUND_FINISH_BTC",
  ROUND_FINISH_ETH = "ROUND_FINISH_ETH",
  ROUND_FINISH_SOL = "ROUND_FINISH_SOL",
  ROUND_WINNER_BTC = "ROUND_WINNER_BTC",
  ROUND_WINNER_ETH = "ROUND_WINNER_ETH",
  ROUND_WINNER_SOL = "ROUND_WINNER_SOL",
  ROUND_WINNER_REFERRAL_BTC = "ROUND_WINNER_REFERRAL_BTC",
  ROUND_WINNER_REFERRAL_ETH = "ROUND_WINNER_REFERRAL_ETH",
  ROUND_WINNER_REFERRAL_SOL = "ROUND_WINNER_REFERRAL_SOL",
  STRATEGY = "STRATEGY",
  REFERRAL_PROGRAM = "REFERRAL_PROGRAM",
  FREE_ROUNDS = "FREE_ROUNDS",
  PAYMENT_NETWORKS = "PAYMENT_NETWORKS",
  PRICES = "PRICES",
  PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL",
}
