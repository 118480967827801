import { RoundWinnerResponse } from "@ns/api";
import { Card, CardContent } from "@ns/client-ui";
import { useTranslation } from "react-i18next";

import { CURRENCY_IMAGE } from "../../constants/currency-images";
import { environmentVariables } from "../../env/enviroment-variables.ts";
import {
  formatDate,
  getCapitalizedFirstLetter,
} from "../../helpers/helpers.ts";

const WinnersCard = ({
  roundNumber,
  bid,
  name,
  currencyCode,
  createdAt,
  reward,
  winnerImageUrl,
  winnerCountryFlag,
}: RoundWinnerResponse) => {
  const { t } = useTranslation();

  return (
    <Card className="bg-transparent w-full h-fit p-[16px] border-[1px] border-solid border-tabBorderColor rounded text-bidCardText">
      <CardContent className="flex flex-row p-0 gap-[0.25rem]">
        <div className={"w-full hidden sm:block"}>
          <img
            className={"w-[1.5rem] h-[1rem]"}
            src={winnerCountryFlag}
            alt={"winner-country"}
          />
          <div className="flex flex-row gap-[0.5rem] items-center w-[100%]">
            {winnerImageUrl ? (
              <img
                src={winnerImageUrl}
                alt={"winner-image"}
                className={"w-[3rem] h-[3rem] rounded-full"}
              />
            ) : (
              <div className="rounded-full bg-placeholderBg  w-[3rem] h-[3rem] flex justify-center items-center">
                <span className="text-black text-[24px]">
                  {getCapitalizedFirstLetter(name)}
                </span>
              </div>
            )}
            <div className="flex flex-col p-0 w-[100%]">
              <div className="flex justify-between text-[16px]">
                <span className="w-1/5 text-center">
                  {`${t("winnerCard.round")} ${roundNumber}`}
                </span>
                <span className="w-1/5 text-center">
                  {t("winnerCard.winner")}
                </span>
                <span className="w-1/5 text-center">
                  {t("winnerCard.winningBid")}
                </span>
                <span className="w-1/5 text-center">
                  {t("winnerCard.date")}
                </span>
                <span className="w-1/5 text-center">
                  {t("winnerCard.reward")}
                </span>
              </div>
              <div className="border-b border-tabBorderColor my-4"></div>
              <div className="flex justify-between text-[14px]">
                <div className="w-1/5 text-center flex justify-center gap-2">
                  <img
                    //@ts-expect-error - currency is a string
                    src={CURRENCY_IMAGE[currencyCode]}
                    alt="Currency"
                    className="w-[18px]"
                  />
                  <span className="w-1/5 text-center">{currencyCode}</span>
                </div>
                <span className="w-1/5 text-center">{name}</span>
                <span className="w-1/5 text-center">
                  {bid} {environmentVariables.fiatCurrency}
                </span>
                <span className="w-1/5 text-center">
                  {formatDate(new Date(createdAt))}
                </span>
                <span className="w-1/5 text-center">
                  {reward} {environmentVariables.fiatCurrency}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/*MOBILE*/}

        <div className="sm:hidden flex flex-row gap-[0.5rem] w-[100%]">
          {winnerImageUrl ? (
            <img
              src={winnerImageUrl}
              alt={"winner-image"}
              className={"w-[3rem] h-[3rem] rounded-full"}
            />
          ) : (
            <div className="rounded-full bg-placeholderBg  w-[3rem] h-[3rem] flex justify-center items-center">
              <span className="text-black text-[24px]">
                {getCapitalizedFirstLetter(name)}
              </span>
            </div>
          )}
          <div className="flex flex-col p-0 w-[100%]">
            <div className="flex flex-col text-[16px]">
              <div className={"flex justify-between"}>
                <span>{`${t("winnerCard.round")} ${roundNumber}`}</span>
                <div className=" flex justify-center gap-2">
                  <img
                    //@ts-expect-error - currency is a string
                    src={CURRENCY_IMAGE[currencyCode]}
                    alt="Currency"
                    className="w-[18px]"
                  />
                  <span>{currencyCode}</span>
                </div>
              </div>
              <div className="border-b border-tabBorderColor my-2"></div>
              <div className={"flex justify-between"}>
                <span>{t("winnerCard.winner")}</span>
                <span>{name}</span>
              </div>
              <div className="border-b border-tabBorderColor my-2"></div>
              <div className={"flex justify-between"}>
                <span>{t("winnerCard.winningBid")}</span>
                <span>
                  {bid} {environmentVariables.fiatCurrency}
                </span>
              </div>
              <div className="border-b border-tabBorderColor my-2"></div>
              <div className={"flex justify-between"}>
                <span>{t("winnerCard.date")}</span>
                <span>{formatDate(new Date(createdAt))}</span>
              </div>
              <div className="border-b border-tabBorderColor my-2"></div>
              <div className={"flex justify-between"}>
                <span>{t("winnerCard.reward")}</span>
                <span>
                  {reward} {environmentVariables.fiatCurrency}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default WinnersCard;
