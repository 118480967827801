const SolToast = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={"h-full w-full"}
    >
      <rect
        x="1.09412"
        y="0.787109"
        width="52"
        height="52"
        rx="26"
        fill="url(#paint0_linear_20012113_36181)"
        fill-opacity="0.8"
      />
      <g clip-path="url(#clip0_20012113_36181)">
        <path
          d="M46.0941 26.7871C46.0941 30.545 44.9798 34.2184 42.892 37.3429C40.8043 40.4675 37.8369 42.9028 34.3651 44.3408C30.8933 45.7789 27.073 46.1551 23.3874 45.422C19.7018 44.6889 16.3163 42.8793 13.6591 40.2221C11.0019 37.5649 9.19232 34.1795 8.4592 30.4938C7.72609 26.8082 8.10235 22.9879 9.54041 19.5161C10.9785 16.0443 13.4138 13.0769 16.5383 10.9892C19.6628 8.90144 23.3363 7.78711 27.0941 7.78711C32.1332 7.78711 36.966 9.78889 40.5292 13.3521C44.0923 16.9153 46.0941 21.748 46.0941 26.7871Z"
          fill="#F7931A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.5979 16.4624L25.9644 17.6318L26.9403 13.9941L29.1236 14.5866L28.1857 18.074L29.9665 18.5524L30.9062 15.027L33.1274 15.6212L32.1705 19.1656C32.1705 19.1656 35.7978 19.9688 36.6511 22.919C37.5043 25.8691 34.7753 27.4185 33.9323 27.4772C33.9323 27.4772 37.1105 29.2201 36.0189 32.6487C34.9273 36.0773 31.5763 36.6905 28.051 35.9046L27.0941 39.5802L24.8728 38.9861L25.8487 35.3674L24.0869 34.8872L23.111 38.5318L20.907 37.9393L21.8846 34.3121L17.4023 33.103L18.532 30.595C18.532 30.595 19.7963 30.9404 20.2748 31.0544C20.7533 31.1684 21.0607 30.671 21.1954 30.1735C21.3302 29.6761 23.3597 21.4318 23.5514 20.753C23.7432 20.0741 23.6654 19.5439 22.8605 19.3349C22.0556 19.1259 20.9605 18.7994 20.9605 18.7994L21.5979 16.4624ZM26.0024 27.3805L24.7933 32.1875C24.7933 32.1875 30.7887 34.3518 31.5556 31.3066C32.3225 28.2614 26.0024 27.3805 26.0024 27.3805ZM26.5586 25.1005L27.7453 20.696C27.7453 20.696 32.8787 21.6149 32.2465 24.0659C31.6143 26.5169 28.5882 25.5772 26.5586 25.1005Z"
          fill="white"
        />
      </g>
      <rect
        x="1.43982"
        y="1.1875"
        width="52"
        height="52"
        rx="26"
        fill="url(#paint1_linear_20012113_36181)"
        fill-opacity="0.8"
      />
      <g clip-path="url(#clip1_20012113_36181)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3656 8.1875C31.1234 8.1875 34.7969 9.30183 37.9214 11.3896C41.046 13.4773 43.4812 16.4447 44.9193 19.9165C46.3574 23.3883 46.7336 27.2086 46.0005 30.8942C45.2674 34.5799 43.4578 37.9653 40.8006 40.6225C38.1434 43.2797 34.758 45.0893 31.0723 45.8224C27.3867 46.5555 23.5664 46.1793 20.0946 44.7412C16.6228 43.3031 13.6554 40.8679 11.5677 37.7433C9.47993 34.6188 8.3656 30.9453 8.3656 27.1875C8.3656 22.1484 10.3674 17.3157 13.9306 13.7525C17.4938 10.1893 22.3265 8.1875 27.3656 8.1875V8.1875Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3655 12.2676L32.3642 19.7363L37.3629 27.2068L27.3655 32.2435L17.368 27.1878L22.3668 19.7173L27.3655 12.2676ZM27.3655 34.2558L17.9225 29.2173L27.3655 42.1079L36.8085 29.2173L27.3655 34.2558Z"
          fill="#343434"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3656 12.2676L32.3643 19.7173L37.3631 27.1878L27.3656 23.4137V12.2676Z"
          fill="#343434"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3656 34.2553L36.8276 29.2168L27.3656 42.1264V34.2553Z"
          fill="#313131"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3656 23.4141L37.3631 27.1882L27.3656 32.2249V23.4141Z"
          fill="#151515"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3655 12.2676L22.3668 19.7173L17.368 27.1878L27.3655 23.4137V12.2676Z"
          fill="#8C8C8C"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3656 34.2553L17.9226 29.2168L27.3656 42.1264V34.2553Z"
          fill="#8A8A8A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.3655 23.4141L17.368 27.1882L27.3655 32.2249V23.4141Z"
          fill="#353535"
        />
      </g>
      <rect
        x="0.844116"
        y="0.884766"
        width="52"
        height="52"
        rx="26"
        fill="url(#paint2_linear_20012113_36181)"
        fill-opacity="0.8"
      />
      <g clip-path="url(#clip2_20012113_36181)">
        <path
          d="M45.8441 26.8848C45.8441 30.6426 44.7298 34.3161 42.642 37.4406C40.5543 40.5651 37.5869 43.0004 34.1151 44.4385C30.6433 45.8765 26.823 46.2528 23.1374 45.5197C19.4518 44.7866 16.0663 42.977 13.4091 40.3198C10.7519 37.6626 8.94232 34.2771 8.2092 30.5915C7.47609 26.9058 7.85235 23.0856 9.29041 19.6138C10.7285 16.142 13.1638 13.1746 16.2883 11.0868C19.4128 8.9991 23.0863 7.88477 26.8441 7.88477C31.8832 7.88477 36.716 9.88655 40.2792 13.4497C43.8423 17.0129 45.8441 21.8457 45.8441 26.8848Z"
          fill="#F7931A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.3479 16.5601L25.7144 17.7294L26.6903 14.0918L28.8736 14.6843L27.9357 18.1716L29.7165 18.6501L30.6562 15.1247L32.8774 15.7189L31.9205 19.2633C31.9205 19.2633 35.5478 20.0664 36.4011 23.0166C37.2543 25.9668 34.5253 27.5162 33.6823 27.5749C33.6823 27.5749 36.8605 29.3177 35.7689 32.7463C34.6773 36.175 31.3263 36.7882 27.801 36.0023L26.8441 39.6779L24.6228 39.0837L25.5987 35.4651L23.8369 34.9849L22.861 38.6294L20.657 38.037L21.6346 34.4097L17.1523 33.2006L18.282 30.6926C18.282 30.6926 19.5463 31.0381 20.0248 31.1521C20.5033 31.2661 20.8107 30.7686 20.9454 30.2712C21.0802 29.7737 23.1097 21.5294 23.3014 20.8506C23.4932 20.1718 23.4154 19.6415 22.6105 19.4325C21.8056 19.2235 20.7105 18.8971 20.7105 18.8971L21.3479 16.5601ZM25.7524 27.4782L24.5433 32.2852C24.5433 32.2852 30.5387 34.4494 31.3056 31.4043C32.0725 28.3591 25.7524 27.4782 25.7524 27.4782ZM26.3086 25.1982L27.4953 20.7936C27.4953 20.7936 32.6287 21.7125 31.9965 24.1635C31.3643 26.6145 28.3382 25.6749 26.3086 25.1982Z"
          fill="white"
        />
      </g>
      <rect
        x="1.34412"
        y="1.11328"
        width="52"
        height="52"
        rx="26"
        fill="url(#paint3_linear_20012113_36181)"
        fill-opacity="0.8"
      />
      <circle cx="27.2167" cy="26.6289" r="19" fill="black" />
      <path
        d="M19.9949 31.1303C20.1266 30.9987 20.3076 30.9219 20.4996 30.9219H37.9123C38.2305 30.9219 38.3896 31.3059 38.1647 31.5308L34.7249 34.9706C34.5933 35.1022 34.4122 35.179 34.2202 35.179H16.8075C16.4893 35.179 16.3302 34.795 16.5552 34.5701L19.9949 31.1303Z"
        fill="url(#paint4_linear_20012113_36181)"
      />
      <path
        d="M19.9949 18.2866C20.1321 18.1549 20.3131 18.0781 20.4996 18.0781H37.9123C38.2305 18.0781 38.3896 18.4621 38.1647 18.6871L34.7249 22.1268C34.5933 22.2585 34.4122 22.3353 34.2202 22.3353H16.8075C16.4893 22.3353 16.3302 21.9513 16.5552 21.7263L19.9949 18.2866Z"
        fill="url(#paint5_linear_20012113_36181)"
      />
      <path
        d="M34.7249 24.6675C34.5933 24.5358 34.4122 24.459 34.2202 24.459H16.8075C16.4893 24.459 16.3302 24.843 16.5552 25.0679L19.9949 28.5077C20.1266 28.6393 20.3076 28.7162 20.4996 28.7162H37.9123C38.2305 28.7162 38.3896 28.3321 38.1647 28.1072L34.7249 24.6675Z"
        fill="url(#paint6_linear_20012113_36181)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20012113_36181"
          x1="1.09412"
          y1="0.787109"
          x2="56.7665"
          y2="5.62841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9A548" />
          <stop offset="0.5" stop-color="#F9E690" />
          <stop offset="0.745543" stop-color="#C39C3E" />
          <stop offset="1" stop-color="#A87715" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20012113_36181"
          x1="1.43982"
          y1="1.1875"
          x2="57.1122"
          y2="6.0288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9A548" />
          <stop offset="0.5" stop-color="#F9E690" />
          <stop offset="0.745543" stop-color="#C39C3E" />
          <stop offset="1" stop-color="#A87715" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20012113_36181"
          x1="0.844116"
          y1="0.884766"
          x2="56.5165"
          y2="5.72606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9A548" />
          <stop offset="0.5" stop-color="#F9E690" />
          <stop offset="0.745543" stop-color="#C39C3E" />
          <stop offset="1" stop-color="#A87715" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20012113_36181"
          x1="1.34412"
          y1="1.11328"
          x2="57.0165"
          y2="5.95458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9A548" />
          <stop offset="0.5" stop-color="#F9E690" />
          <stop offset="0.745543" stop-color="#C39C3E" />
          <stop offset="1" stop-color="#A87715" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_20012113_36181"
          x1="-22.9059"
          y1="14.8486"
          x2="-23.3692"
          y2="38.1614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFA3" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_20012113_36181"
          x1="-28.1753"
          y1="12.0967"
          x2="-28.6385"
          y2="35.4094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFA3" />
          <stop offset="1" stop-color="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_20012113_36181"
          x1="-25.5574"
          y1="13.464"
          x2="-26.0207"
          y2="36.7768"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.37" stop-color="#00FFA3" />
          <stop offset="0.665" stop-color="#DC1FFF" />
        </linearGradient>
        <clipPath id="clip0_20012113_36181">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(8.09412 7.78711)"
          />
        </clipPath>
        <clipPath id="clip1_20012113_36181">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(8.43982 8.1875)"
          />
        </clipPath>
        <clipPath id="clip2_20012113_36181">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(7.84412 7.88477)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SolToast;
