import finalStepImage from "/assets/final-step.png";
import stealDealPng from "/assets/steal-deal-logo.png";
import discountWithBackground from "/svg/discount-background.svg";
import discount from "/svg/discount.svg";
import friends from "/svg/friends.svg";
import giftOnboard from "/svg/gift-onboard.svg";
import giftSvg from "/svg/gift.svg";
import referral from "/svg/referral.svg";
import tickCircleSvg from "/svg/tick-circle.svg";
import logo from "/assets/logo-button.png";

import { featureFreeBid } from "../../utils/feature-flags/feature-flags.ts";

import { useTranslation } from "react-i18next";

import { VideoPlayer } from "../video-player.tsx";

import { useQuery } from "@tanstack/react-query";

import stealDeal from "../../../public/svg/steal-deal.svg";
import { api } from "../../api/client-api.ts";
import { CURRENCY_IMAGE } from "../../constants/currency-images.ts";

export const FirstPage = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <div className={"flex flex-col items-center"}>
        <span className={"text-white text-[36px] avenir-semibold"}>
          {t("onboardWizard.cryptoAuction")}
        </span>
        <span className={"gold-text-clear text-[18px] avenir-semibold"}>
          {t("onboardWizard.withLowest")}
        </span>
      </div>
      <img src={stealDealPng} className={"w-[313px] h-[222px]"} alt="logo" />
      <img src={stealDeal} className={"w-[220px] h-[45px]"} />
    </div>
  );
};

export const SecondPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center gap-[32px]">
      <div className="flex flex-col items-center text-center gap-[10px]">
        <span className="text-white avenir-semibold text-[18px]">
          {t("onboardWizard.participateText")}
        </span>
      </div>
      <div className="flex flex-col gap-[16px]">
        <div className="flex gap-[15px] flex-col items-center w-full">
          <div className={"relative rounded-[12px]"}>
            <div className="rounded-[12px] absolute card-border z-10" />
            <div className="z-20 relative flex w-[300px] h-[100px] p-[1rem] flex-1 items-center flex-row justify-between bg-cryptoCardBg rounded-[12px] gap-[10px]">
              <div className={"flex w-full"}>
                <div className={"mb-2 flex grow items-center gap-[0.5rem]"}>
                  <div>
                    <img
                      src={CURRENCY_IMAGE["BTC"]}
                      className={"w-[40px] h-[40px]"}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-[#F7931A] text-[16px] font-extrabold">
                      0.1 {t("common.bitcoin")}
                    </span>
                    <span className="text-white text-[14px]">
                      {t("onboardWizard.bid")}
                    </span>
                  </div>
                </div>

                <div className="avenir-semibold text-[#F7931A] text-[14px] text-right">
                  {"10 USDT"}
                </div>
              </div>
            </div>
          </div>
          <div className={"relative rounded-[12px]"}>
            <div className="rounded-[12px] absolute card-border z-10" />
            <div className="z-20 relative flex w-[300px] h-[100px] p-[1rem] flex-1 items-center flex-row justify-between bg-cryptoCardBg rounded-[12px] gap-[10px]">
              <div className={"flex w-full"}>
                <div className={"mb-2 flex grow items-center gap-[0.5rem]"}>
                  <div>
                    <img
                      src={CURRENCY_IMAGE["ETH"]}
                      className={"w-[40px] h-[40px]"}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-white text-[16px] font-extrabold">
                      {t("common.ethereum")}
                    </span>
                    <span className="text-white text-[14px]">
                      {t("onboardWizard.bid")}
                    </span>
                  </div>
                </div>

                <div className="avenir-semibold text-white text-[14px] text-right">
                  {"5 USDT"}
                </div>
              </div>
            </div>
          </div>
          <div className={"relative rounded-[12px]"}>
            <div className="rounded-[12px] absolute card-border z-10" />
            <div className="z-20 relative flex w-[300px] h-[100px] p-[1rem] flex-1 items-center flex-row justify-between bg-cryptoCardBg rounded-[12px] gap-[10px]">
              <div className={"flex w-full"}>
                <div className={"mb-2 flex grow items-center gap-[0.5rem]"}>
                  <div>
                    <img
                      src={CURRENCY_IMAGE["SOL"]}
                      className={"w-[40px] h-[40px]"}
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-[#00FDF6] text-[16px] font-extrabold">
                      {t("common.solana")}
                    </span>
                    <span className="text-white text-[14px]">
                      {t("onboardWizard.bid")}
                    </span>
                  </div>
                </div>

                <div className="avenir-semibold text-[#00FDF6] text-[14px] text-right">
                  {"1 USDT"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ThirdPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center mt-[3.5rem] gap-[48px]">
      <div className="flex flex-col items-center text-center gap-[10px]">
        <span className="text-white avenir-semibold text-[18px]">
          {t("onboardWizard.stealADealAndBeRewarded")}
        </span>
        <span className="text-textGray4 text-[14px]">
          {t("onboardWizard.referralDescription")}
        </span>
      </div>
      <div className="flex flex-1 justify-center items-center flex-col gap-[8px] sm:gap-[8px]">
        {featureFreeBid && (
          <div className={"relative rounded-[12px]"}>
            <div className="rounded-[12px] absolute card-border z-10" />
            <div className="z-20 relative flex w-[300px] h-[100px] p-[1rem] flex-1 items-center flex-row justify-between bg-cryptoCardBg rounded-[12px] gap-[10px]">
              <div className={"pl-[1.25rem] flex flex-col"}>
                <div className={"flex flex-row items-center gap-[0.5rem]"}>
                  <img src={giftSvg} className={"w-[1.25rem] h-[1.25rem]"} />
                  <span className="text-white text-[16px] avenir-semibold">
                    {t("onboardWizard.freeBid")}
                  </span>
                </div>
                <span className="text-textGray text-[12px]">
                  {t("onboardWizard.afterPlacing")}
                </span>
              </div>
              <img
                src={giftOnboard}
                className={"w-[60px] h-[60px] pr-[1.25rem]"}
                alt="gift"
              />
            </div>
          </div>
        )}
        <div className={"relative rounded-[12px]"}>
          <div className="rounded-[12px] absolute card-border z-10" />
          <div className="z-20 relative flex w-[300px] h-[160px] p-[1rem] flex-1 items-center flex-row justify-between bg-cryptoCardBg rounded-[12px] gap-[10px]">
            <div className={"pl-[1.25rem] flex flex-col"}>
              <div className={"flex flex-row items-center gap-[0.5rem]"}>
                <img src={friends} className={"w-[1.25rem] h-[1.25rem]"} />
                <span className="text-white text-[16px] avenir-semibold">
                  {t("onboardWizard.referralProgram")}
                </span>
              </div>
              <span className="text-textGray text-[12px]">
                {t("onboardWizard.inviteFriends")}
              </span>
            </div>
            <img
              src={referral}
              className={"w-[60px] h-[60px] pr-[1.25rem]"}
              alt="referral"
            />
          </div>
        </div>
        <div className={"relative rounded-[12px]"}>
          <div className="rounded-[12px] absolute card-border z-10" />
          <div className="z-20 relative flex w-[300px] h-[100px] p-[1rem] flex-1 items-center flex-row justify-between bg-cryptoCardBg rounded-[12px] gap-[10px]">
            <div className={"pl-[1.25rem] flex flex-col"}>
              <div className={"flex flex-row items-center gap-[0.5rem]"}>
                <img src={discount} className={"w-[1rem] h-[1rem]"} />
                <span className="text-white text-[16px] avenir-semibold">
                  {t("onboardWizard.promoCode")}
                </span>
              </div>
              <span className="text-textGray text-[12px]">
                {t("onboardWizard.useDiscount")}
              </span>
            </div>
            <img
              src={discountWithBackground}
              className={"w-[60px] h-[60px] pr-[1.25rem]"}
              alt="gift"
            />
          </div>
        </div>
        <span className="text-gray-600 text-[9px]">
          {t("onboardWizard.referralCanBeCombined")}
        </span>
      </div>
    </div>
  );
};

export const FourthPage = () => {
  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ["video-onboarding"],
    queryFn: () => api.contentful.getOnboardingVideo(),
  });

  return (
    <div className="flex flex-col justify-center items-center">
      <div>
        <img src={logo} alt="Logo A Steal Deal" />
      </div>
      <div className="flex flex-col items-center text-center gap-[16px] mt-[2rem]">
        <span className="gold-text-clear avenir-semibold text-[18px]">
          {t("onboardWizard.everyoneCanPlay")}
        </span>
        <span className="text-textGray4 text-[14px]">
          {t("onboardWizard.seeTutorial")}
        </span>
      </div>
      <div className="flex flex-1 justify-center items-center mt-[2rem] mb-[7rem] sm:mb-[4rem]">
        <VideoPlayer videoUrl={data?.video ?? ""} />
      </div>
    </div>
  );
};

export const FifthPage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center gap-[24px]">
      <div className="flex flex-col items-center text-center gap-[12px]">
        <span className="text-white avenir-semibold text-[18px]">
          {t("onboardWizard.allSet")}
        </span>
      </div>
      <img src={tickCircleSvg} alt={"tick circle"} />
      <div className="flex flex-1 justify-center items-center flex-col gap-[20px]">
        <img src={finalStepImage} alt={"all set"} />
      </div>
    </div>
  );
};
