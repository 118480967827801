/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BannerMessageResponse } from "../models/BannerMessageResponse";
import type { UpdateBannerRequest } from "../models/UpdateBannerRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class BannerApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get banner
   * @returns BannerMessageResponse Banner
   * @throws ApiError
   */
  public getAdminBanner(): CancelablePromise<BannerMessageResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/banner",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Update banner
   * @param requestBody Update banner
   * @returns any Successfully updated banner
   * @throws ApiError
   */
  public updateBanner(
    requestBody?: UpdateBannerRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/banner",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get banner
   * @returns BannerMessageResponse Banner
   * @throws ApiError
   */
  public getBanner(): CancelablePromise<BannerMessageResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/banner",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get list of banners
   * @returns BannerMessageResponse[] List of banners
   * @throws ApiError
   */
  public getAdminBanners(): CancelablePromise<BannerMessageResponse[]> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/banner",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get banner by ID
   * @param bannerId Banner ID
   * @returns BannerMessageResponse Banner
   * @throws ApiError
   */
  public getAdminBannerById(bannerId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "GET",
      url: `/admin/banner/${bannerId}`,
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Create new banner
   * @param requestBody Create banner
   * @returns any Successfully created banner
   * @throws ApiError
   */
  public createBanner(
    requestBody?: UpdateBannerRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/banner",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Update banner by ID
   * @param bannerId Banner ID
   * @param requestBody Update banner
   * @returns any Successfully updated banner
   * @throws ApiError
   */
  public updateBannerById(
    bannerId: string,
    requestBody?: UpdateBannerRequest
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PUT",
      url: `/admin/banner/${bannerId}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Delete banner by ID
   * @param bannerId Banner ID
   * @returns any Successfully deleted banner
   * @throws ApiError
   */
  public deleteBannerById(bannerId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: `/admin/banner/${bannerId}`,
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }
}
