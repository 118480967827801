import { useTranslation } from "react-i18next";

interface ControlledReferralProps {
  referalCode: string | undefined;
  icon: string;
}

const ControlledReferral = ({ referalCode, icon }: ControlledReferralProps) => {
  const { t } = useTranslation();
  const isDisabled = !referalCode;

  return (
    <div className="w-full flex items-center justify-center">
      <div
        className={`w-[235px] flex items-center justify-between p-2 rounded-lg ${
          isDisabled ? "bg-[#C4C4C4]" : "bg-white"
        }`}
      >
        <div className="flex items-center gap-2">
          <img src={icon} alt="icon" />
          <div className="text-left">
            <p className="text-xs text-[8px] text-gray-700">
              {t("giftDialog.yourReferalId")}
            </p>
            <p
              className={`font-bold text-[12px] ${isDisabled ? "text-gray-600" : "text-black"}`}
            >
              {isDisabled ? "XXX" : referalCode}
            </p>
          </div>
        </div>

        <button
          className={`px-4 py-2 font-medium text-sm rounded-lg ${
            isDisabled
              ? "bg-gray-500 text-[#65A8AD] cursor-not-allowed"
              : "bg-cyan-300 text-black hover:bg-cyan-400"
          }`}
          disabled={isDisabled}
          onClick={() => {
            if (!isDisabled) navigator.clipboard.writeText(referalCode);
          }}
        >
          {t("common.copy")}
        </button>
      </div>
    </div>
  );
};

export default ControlledReferral;
